
import React,{useState} from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,Stack,RadioGroup,
  Text,
  useColorModeValue,Radio, Link, color
} from "@chakra-ui/react";
import { useHistory } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/hooks';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import axios from "axios";
import { useCookies } from 'react-cookie';
import { MdOutlineFileDownloadDone} from 'react-icons/md'
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { BiErrorCircle } from "react-icons/bi";
import { PhoneInput } from 'react-international-phone';
import { BASE_URL_1 ,BASE_URL_2} from "config";
function SignUp() {
  const textToCopy = 'SA73 8000 0445 6080 1015 4873';

  const handleCopyClick = () => {
    navigator.clipboard.writeText(textToCopy);
  };
  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
  )
  // Chakra color mode
  const [cookies, setCookie, removeCookie] = useCookies(['name','id','pricing','id_office','passwor','type', 'email','token','isOffice','phone','phone_01','phone_2','phone_fixe','fall_number','establishment_number','address']);
   
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure(); // First modal
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  const [overlay, setOverlay] = useState(<OverlayOne />)
  function isValid(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log(emailRegex.test(email))
    return emailRegex.test(email);
  }


  
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
 
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [email,setEmail]=useState('')
  const [password,setPassword]=useState('')
  const [isOffice,setIsOffice]=useState('')
  const[message,setMessage]=useState('')
  const [name,setName]=useState('')
  const [address,setAdress]=useState('')
  const[phone,setPhone]=useState('')
  const[tel_01,setTel01]=useState('')
  const [tel_02,setTel02]=useState('')
  const[tel_fixe,setTelFixe]=useState('')
  const [fall_number,setFallNumber]=useState('')
  const [establishment_number,setEtablishment]=useState('')

   const handleSubmit= async =>{
        const pricing =cookies.pricing
      axios.post(`${BASE_URL_2}/users/signUp`, {name,phone,tel_01,tel_02,tel_fixe,address,fall_number,establishment_number,pricing, email, password })
      .then((response) => {
        const result = response.data; // No need to parse JSON, response.data is already parsed
        if(result.message)
        {
          setMessage(result.message)
          setOverlay(<OverlayOne />)
          onOpen1()
        }
        else{
       
        if(pricing=="مجانية")
        history.push('/maktabi/sign-in');
       else{
           setMessage("الشهري حقوق الاشتراك  ادفع  للحساب التالي    وانتظر تفعيل حسابك لتستفيد من باقتك. " )
          setOverlay(<OverlayOne />)
          onOpen2()
       } 
     
        }
     
     
      })
      .catch((error) => {
        console.log(error)}
      )
 
      .catch((error) => {
        console.log(error)
        
       
      });
    }
   
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto",md:'auto',xl:"0px" }}
        me='auto'
        h='100%'
        alignItems='left'
        textAlign='right'
        justifyContent='right'
        mb={{ base: "0px", md: "20px"}}
        px={{ base: "5px", md: "0px" }}
        mt={{ base: "0px", md: "20px" }}
        flexDirection='column'>
        <Box >
          <Heading color={textColor} fontSize='25px' mb='10px'>
          {cookies.pricing=="مجانية"? "ابدا التجربة المجانية الان ولمدة 15 يوم" : `    سجل في الباقة ${cookies.pricing}`  }
      
          </Heading>
 <Text
            mb='10px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
           ادخل معلومات التسجيل لتنطلق الرحلة</Text> 
        
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "520px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
        
          <Flex align='center' mb='5px'>
            <HSeparator />
          
            <HSeparator />
          </Flex>
          <Flex align='center' mb='10px' flexDir="row" >
          <FormControl marginRight="20px" >
                <FormLabel
            display='flex'
            alignItems='center'  // Align label text vertically to the center
            justifyContent='flex-end'  // Align label text to the right
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'
            pr='4px'  // Add right padding to create space between label text and input field
          >
             العنوان<Text color={brandStars}>*</Text>
          </FormLabel>
            <Input
            
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='العنوان'
             mb='15px'
              fontWeight='500'
              size='lg'
              onChange={(event)=> {
                setAdress(event.target.value)
            
              }}
            />
            </FormControl>
          <FormControl mb='5px'>
        
          <FormLabel
            display='flex'
            alignItems='center'  // Align label text vertically to the center
            justifyContent='flex-end'  // Align label text to the right
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'
            pr='4px'  // Add right padding to create space between label text and input field
          >
             الاسم<Text color={brandStars}>*</Text>
          </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='الاسم'
             mb='15px'
              fontWeight='500'
              size='lg'
              onChange={(event)=> {
                setName(event.target.value)
            
              }}
            />
            </FormControl >
         
            </Flex>
            {cookies.pricing!="مجانية"? 
            <>
               <Flex align='center' mb='10px' flexDir="row" >
   
            <FormControl marginLeft="-20px">
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'
              justifyContent='flex-end'  >
               رقم الهاتف الثاني<Text color={brandStars}></Text>
            </FormLabel>
            <PhoneInput
        
       style={{justifyContent:"flex-end"}}
        defaultCountry="sa"
        textAlign="left"
        onChange={(phone) => setTel02(phone)}
    />
            </FormControl>
            <FormControl  >
          <FormLabel
            display='flex'
            alignItems='center'  // Align label text vertically to the center
            justifyContent='flex-end'  // Align label text to the right
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'
            pr='4px'  // Add right padding to create space between label text and input field
          >
             رقم الهاتف الاول<Text color={brandStars}>*</Text>
          </FormLabel>
          <PhoneInput
        
        style={{justifyContent:"flex-end"}}
        defaultCountry="sa"
        textAlign="left"
        onChange={(phone) => setTel01(phone)}
    />
            </FormControl>
            </Flex>
            
            <Flex align='center' mb='10px' flexDir="row" >
            <FormControl marginRight="20px" >
            <FormLabel
              ms='4px'
              mt="20px"
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'
              justifyContent='flex-end'  >
                 رقم المكتب<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
            <Input
            
            isRequired={true}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='email'
           
           mb='15px'
            fontWeight='500'
            size='lg'
            onChange={(event)=> {
              setEtablishment(event.target.value)
              
            }}
          />
              
            </InputGroup>
            </FormControl>
          <FormControl>
          <FormLabel
            display='flex'
            alignItems='center'  // Align label text vertically to the center
            justifyContent='flex-end'  // Align label text to the right
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'
            pr='4px'  // Add right padding to create space between label text and input field
          >
             رقم الهاتف الثابت<Text color={brandStars}></Text>
          </FormLabel>
          <PhoneInput
            style={{justifyContent:"flex-end"}}
        defaultCountry="sa"
        textAlign="left"
      onChange={(phone) => setTelFixe(phone)}
    />
            </FormControl>
           
            </Flex>
            
            <Flex align='center' mb='10px' flexDir="row" >
              
         
          <FormControl marginRight="20px" >
            <FormLabel
              ms='4px'
              mt="20px"
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'
              justifyContent='flex-end'  >
                رقم  رخصة فال<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
            <Input
            
            isRequired={true}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='email'
           
           mb='15px'
            fontWeight='500'
            size='lg'
            onChange={(event)=> {
              setFallNumber(event.target.value)
           
            }}
          />
              
            </InputGroup>
            </FormControl>
   
           
    
          
       

            </Flex>
            
            
            </>
            
           : <Flex align='center' alignItems='center'  mb='10px' flexDir="row"  justifyContent={"center"} >
                      <FormControl >
          <FormLabel
            display='flex'
            alignItems='center'  // Align label text vertically to the center
            justifyContent='flex-end'  // Align label text to the right
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'
            pr='4px'  // Add right padding to create space between label text and input field
          >
             رقم  رخصة فال<Text color={brandStars}>*</Text>
          </FormLabel>
            <Input
            
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
             mb='15px'
              fontWeight='500'
              size='lg'
              onChange={(event)=> {
                setFallNumber(event.target.value)
             
              }}
            />
            </FormControl>
           <FormControl>
           <FormLabel
           display='flex'
           alignItems='center'  // Align label text vertically to the center
           justifyContent='flex-end'  // Align label text to the right
           fontSize='sm'
           fontWeight='500'
           color={textColor}
           mb='8px'
           pr='4px'  // Add righ
           >
              رقم الهاتف <Text color={brandStars}>*</Text>
           </FormLabel>
           <PhoneInput
        
        style={{justifyContent:"flex-end"}}
        defaultCountry="sa"
        textAlign="right"
       onChange={(phone) => setPhone(phone)}
    />
             </FormControl>
   
   
             </Flex>
              }
            <Flex align='center'  flexDir="row" >
          <FormControl mb='5px' marginRight="20px">
          <FormLabel
            display='flex'
            alignItems='center'  // Align label text vertically to the center
            justifyContent='flex-end'  // Align label text to the right
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'
            pr='4px'  // Add right padding to create space between label text and input field
          >
            البريد الالكتروني<Text color={brandStars}>*</Text>
          </FormLabel>
            <Input
            
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
            type='email'
              placeholder='mail@simmmple.com'
             mb='15px'
              fontWeight='500'
              size='lg'
              onChange={(event)=> {
                setEmail(event.target.value)
               
              }}
            />
            </FormControl>
            <FormControl>
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'
              justifyContent='flex-end'  >
              كلمة المرور<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='Min. 8 characters'
               mb='15px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                onChange={(event)=> {
                  setPassword(event.target.value)
                }}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            </FormControl>
            </Flex>
           {cookies.pricing==="مجانية" &&
            <Button
            disabled={email&&isValid(email)&&fall_number&&password&&name&&address&&phone ?false :true}
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
             mb='15px' type="submit" 
              onClick={handleSubmit} >
              اشترك الان
            </Button>
          } 

          {cookies.pricing!="مجانية" &&
            <Button
            disabled={email&&isValid(email)&&password&&name&&address&&tel_01&&fall_number&&establishment_number?false :true}
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
             mb='15px' type="submit" 
              onClick={handleSubmit} >
              اشترك الان
            </Button>
          } 
          

         
        </Flex>
        <Modal isCentered    isOpen={isOpen1} onClose={onClose1}>
        {overlay}
        <ModalContent textAlign="center">
          <ModalHeader>حدث خطا</ModalHeader>
          <Icon as={BiErrorCircle} width='50px' height='50px' color='red' margin="auto" />
          <ModalCloseButton />
          <ModalBody>
            <Text>{message}</Text>
          </ModalBody>
          <ModalFooter>
            <Button  onClick={onClose1}>اغلاق</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal closeOnOverlayClick={false}  isCentered    isOpen={isOpen2} onClose={onClose2}>
        {overlay}
      <ModalContent textAlign="center">
          <ModalHeader>عملية ناجحة</ModalHeader>
          <Icon as={MdOutlineFileDownloadDone} width='50px' height='50px' color='green' margin="auto" />
          <ModalCloseButton />
          <ModalBody>
        
            <Text>
            ادفع حقوق الاشتراك الشهري لباقتك و انتطر تفعيل حسابك
            </Text>
          </ModalBody>
          <ModalFooter>      
            <div style={{ width:'100%', display: 'flex', justifyContent: 'space-between' }}>
              <Link href='/maktabi/home'>
                <Button onClick={onClose2}>اغلاق</Button>
              </Link>
              <Button onClick={handleCopyClick}>نسخ الحساب</Button>
            </div>

          </ModalFooter>
        </ModalContent>
      </Modal>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;

