import React,{useState,useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,SimpleGrid,Flex,Text, useColorModeValue,Icon,Button  ,Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,

  Textarea,
  ModalCloseButton,FormControl,FormLabel,Input, Link,
} from '@chakra-ui/react'
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { IoIosAddCircle } from 'react-icons/io';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from "components/card/Card";
import { MdOutlineEdit } from 'react-icons/md';
import { AiFillDelete } from 'react-icons/ai';
import {FiMoreHorizontal} from 'react-icons/fi'
import ReactLoading from 'react-loading';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { BiNotepad } from 'react-icons/bi';
import { BASE_URL_2 } from 'config';
export default function MyOrders()  {
const [cookies, setCookie, removeCookie] = useCookies(['name','id','id_office', 'email','token','isOffice','phone','phone_01','phone_2','phone_fixe','fall_number','establishment_number','address']);
const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
const [loading,setLoading]=useState(true)
const iconColor = useColorModeValue("brand.500", "white");
const [proposales,setProposals]=useState([])
 const [orders,setOrders]=useState([])
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
const [id_real_estate,setIdRealEstate]=useState('')
const [town,setTown]=useState('')
const [city,setCity]=useState('')
const [type,setType]=useState('')
  const notify = (text) => toast.success( text,{position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored", 
  fontFamilly:"Cairo"
});
const notify2 = (text) => toast.error( text,{position: "bottom-right",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
theme: "colored", 
fontFamilly:"Cairo"
});
const history = useHistory();


  
  useEffect(() => {
    const email= cookies.email
    const proposal="false"
    axios.post(`${BASE_URL_2}/real_estates/getByUser`,{email,type})
      .then((response) => {
        const result = response.data
   
        setProposals(result.real_estates)
        setLoading(false)
      })
    
  }, [])

useEffect(() => {
    const email= cookies.email


    axios.post(`${BASE_URL_2}/real_estates/orders/user/search`,{email,id_real_estate,town,city,type})
      .then((response) => {
        const result = response.data
       
        setOrders(result.real_estates)
      
      })

  
}, [id_real_estate,town,city,type])


const delet = async (id_real_estate,proposal,id_office,id_user)=>{

  axios.post(`${BASE_URL_2}/real_estates/delete`,{id_real_estate,proposal,id_office,id_user})
  .then((response) => {
    const result = response.data
    if(result.succes){
        const id_office= cookies.id_office
        axios.post(`${BASE_URL_2}/real_estates/orders/getAll`,{id_office})
          .then((response) => {
            const result = response.data
        
            setOrders(result.real_estates)
          })
        notify("تم حذف العرض  بنجاح")

    }
    else{
     
      notify2(result.message)
    }
  
  })


}



  
return ( <> 
  {loading==true?   <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh', // Set minimum height to fill the viewport

  }}> <ReactLoading  type="spin" color="#422AFB" height={100} width={80} /></div>
 :(
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <SimpleGrid
      mb='20px'
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}>
         <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
         قائمة طلباتي
        </Text>
      
       <Link href='/dashboard/order/add'>
       <Button  
       backgroundColor={bgButton}  
        borderRadius='10px'   
        color={iconColor}  
        onClick={()=>{
        
        }}
      
        >اضافة طلب <Icon as={IoIosAddCircle} width='20px' mr='5px' height='20px' color={iconColor} /></Button></Link>
      </Flex>
      <Flex px='25px' justify='space-between' m='20px' mb="30px"  align='center'>
      <FormLabel> بحث</FormLabel>
        <Input onChange={(e)=>{
            setIdRealEstate( parseInt(e.target.value))
           
        }}  placeholder='رقم العقار' ml="30px"/>
        <Input onChange={(e)=>{
            setType( e.target.value)
           
        }}  placeholder='نوع العقار' ml="30px"/>
        
        <Input   onChange={(e)=>{
            setTown(e.target.value)
           
        }} placeholder='المدينة 'ml="30px" />
        <Input  onChange={(e)=>{
            setCity(e.target.value)
           
        }} placeholder=' الحي' ml="30px"/>

      </Flex>
      <Table  variant='simple' color='gray.500' mb='24px'>
      <TableContainer>
    <Table variant='simple'>
    <Thead>
        
            <Tr >
             
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                  رقم العقار
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                   نوع العقار
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                     الحي
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                     المدينة
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                    صاحب العقار
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                   سعر العقار
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                   المزيد
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "20px" }}
                    color='gray.400'>
                    
                  </Flex>
                  العمليات
                </Th>
            
            </Tr>
         
        </Thead>
      <Tbody>
        {orders.map((item,index)=>{
          return(
            <Tr>
            <Td>{item.id_real_estate}</Td>
            <Td>{item.type}</Td>
            <Td>{item.town}</Td>
            <Td>{item.city}</Td>
            <Td>{item.email}</Td>
            <Td>{item.price}  ر. س </Td>
            <Td > 
            <Icon as={FiMoreHorizontal} width='20px' mr='10px' height='20px'  cursor='pointer' color={iconColor} onClick={()=>{
              setCookie('id_real_estate',item.id_real_estate, { path: '/', expires: new Date(Date.now() + 86400000) });
              history.push('/dashboard/order/details');
          }} /></Td>
            <Td ><Icon as={MdOutlineEdit} width='20px' mr='10px' height='20px' cursor='pointer' color={iconColor}   onClick={()=>{
              setCookie('id_real_estate',item.id_real_estate, { path: '/', expires: new Date(Date.now() + 86400000) });
              history.push('/dashboard/order/update');
          }}    /> 
            
            <Icon as={AiFillDelete} width='20px' mr='10px' height='20px'  cursor='pointer' color={iconColor}  onClick={()=>delet(item.id_real_estate,item.proposal,item.id_office,item.id_user)}/></Td>
          
          </Tr>
          )
        })}  
      </Tbody>
    </Table>
  </TableContainer>
      </Table>
    </Card>
          
    </SimpleGrid>
    
      <ToastContainer  position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"/>
  </Box>

 )}</>)
}



