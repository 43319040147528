import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdLock,
  MdOutlineShoppingCart,

} from "react-icons/md";

import {
BiMap


} from "react-icons/bi";

import {
  HiUsers
  } from "react-icons/hi";

import {
    BsCartFill,
    BsFillBuildingFill
    } from "react-icons/bs";
import { GiModernCity
      } from "react-icons/gi";
import { TbHomeCog,TbHomeSearch
      }  from "react-icons/tb"; 
      

import MainDashboard from "views/main/default";
import City from "views/main/city/index";
import Town from "views/main/town/index";
import Type from "views/main/type/index";
import Users from "views/main/users";
import Profile from "views/main/profile";
import Proposales from "views/main/proposales";
import Orders from "views/main/orders";
import SignInCentered from "views/auth/signIn";
import ProposalAdd from "views/main/proposalAdd";
import OrderAdd from "views/main/orderAdd";
import Map from "views/main/map";
import ProposalDetails from "views/main/proposel-datails";
import OrderDetails from "views/main/order-details";
import PropsalUpdate from "views/main/proposalUpdate";
import OrderUpdate from "views/main/orderUpdate";
import MyProposals from "views/main/myProposals";
import MyOrders from "views/main/myOrders";
import Landing from "views/landing";
import SignUp from "views/auth/signUp";
import { useCookies } from 'react-cookie';
import { SocialMedia } from "views/main/social_media";


const routes = [
  {
    name: "الرئيسية",
    layout:"/dashboard",
    path:"/real-estates",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
    all:true,
  },
  {
    name: "الحساب",
    layout:"/dashboard",
    path:"/profile",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: Profile,
    all:true,
  },
  {
    name: "المستخديمن",
    layout: "/dashboard",
    path: "/users",
    icon: <Icon as={HiUsers} width='20px' height='20px' color='inherit' />,
    component: Users,
    isOffice:true
  },

  {
    name: "الاعدادات",
    category:true,
    items:[
      {name: "اضافة مدينة",
      layout:"/dashboard",
      path: "/town",
      all:true,
      icon: <Icon as={GiModernCity} width='20px' height='20px' color='inherit'/>,
      component: Town},
      {name: "اضافة حي",
      layout:"/dashboard",
      path: "/city",
      all:true,
      icon: (
        <Icon
          as={BsFillBuildingFill}
          width='20px'
          height='20px'
          color='inherit'
        />
      ),
      component:City
      },
      {name: "اضافة نوع عقار",
      layout:"/dashboard",
      path: "/type",
      all:true,
      icon: (
        <Icon
          as={TbHomeCog}
          width='20px'
          height='20px'
          color='inherit'
        />
      ),
      component:Type

      }
    ]
  },
  {
    name: "العقارات",
    category:"true",
    items:[
      {name: "خريطة العقارات",
      layout:"/dashboard",
      path: "/map",
      all:true,
      icon: (
        <Icon
          as={BiMap}
          width='20px'
          height='20px'
          color='inherit'
        />
      ),
      component:Map
      },
      {name: "العروض",
      layout:"/dashboard",
      path: "/proposales",
      pricing:true,
    
      component: Proposales,
      icon: (
        <Icon
          as={BsCartFill}
          width='20px'
          height='20px'
          color='inherit'
        />
      ),

      },
      {name: "عروضي",
      layout:"/dashboard",
      path: "/myproposales",
      component: MyProposals,
      isOffice:false,
      icon: (
        <Icon
          as={BsCartFill}
          width='20px'
          height='20px'
          color='inherit'
        />
      ),

      },
      {name: "الطلبات",
      layout:"/dashboard",
      path: "/orders",
      pricing:true,
     
      icon: (
        <Icon
          as={TbHomeSearch}
          width='20px'
          height='20px'
          color='inherit'
        />
      ),
      component: Orders,
      },
      {name: "طلباتي",
      layout:"/dashboard",
      path: "/myorders",
      isOffice:false,
      icon: (
        <Icon
          as={TbHomeSearch}
          width='20px'
          height='20px'
          color='inherit'
        />
      ),
      component: MyOrders,
      },
   
    ]
  }, 
  {
    name: "Sign In",
    layout: "/maktabi",
    path: "/sign-in",
    all:true,
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
   
  
  },
  {
    name: "Sign Up",
    layout: "/maktabi",
    path: "/sign-up",
    all:true,
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignUp,
   
  
  },
  {
    name: "social_media",
    path: "/media/:clientId",
    layout:'/maktabi',
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SocialMedia,
   
  
  },


  {
    name: "اضافة عرض",
    layout: "/dashboard",
    all:true,
    path: "/proposals/add",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ProposalAdd,
   
  
  },
  {
    name: "اضافة طلب",
    layout: "/dashboard",
    all:true,
    path: "/order/add",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: OrderAdd,
   
  
  },
  {
    name: "تفاصيل العرض",
    layout: "/dashboard",

    path: "/proposal/details",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ProposalDetails,
   
  
  },
  {
    name: "تفاصيل الطلب",
    layout: "/dashboard",
 
    path: "/order/details",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: OrderDetails,
   
  
  },
  {
    name: " تعديل العرض",
    layout: "/dashboard",

    path: "/proposal/update",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: PropsalUpdate,
   
  
  },
  {
    name: " تعديل الطلب",
    layout: "/dashboard",

    path: "/order/update",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: OrderUpdate,
   
  
  },
  {
    name: "home",
    path: "/",
    layout:'/',
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: Landing,
   
  
  },

];

export default routes;
