import React,{useState,useEffect} from 'react'

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,SimpleGrid,Flex,Text, useColorModeValue,Icon,Button  ,Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  ModalCloseButton,FormControl,FormLabel,Input,
} from '@chakra-ui/react'
import { SocialIcon } from 'react-social-icons'
import { MdOutlineEdit } from 'react-icons/md';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { IoIosAddCircle } from 'react-icons/io';
import { useDisclosure } from '@chakra-ui/hooks';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import Card from "components/card/Card";
import { BASE_URL_2 } from 'config';
export default function  Profile ()  {
  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
  )
const iconColor = useColorModeValue("brand.500", "white");
const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure(); // First modal
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure(); // First modal
  const [overlay, setOverlay] = useState(<OverlayOne />)
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const [cookies, setCookie, removeCookie] = useCookies(['name','id_office','pricing','create_at','id','type','password', 'email','token','isOffice','phone','phone_01','phone_02','phone_fixe','fall_number','establishment_number','address']);
    const [name,setName]=useState(cookies.name)
    const [password,setPassword]=useState(cookies.password)
    const [phone,setPhone]=useState(cookies.phone)
    const [type,setType]=useState(cookies.phone)
    const [address,setAddresss]=useState(cookies.address)
    const [phone_01,setPhone01]=useState(cookies.phone_01)
    const [phone_02,setPhone02]=useState(cookies.phone_02)
    const [phone_fixe,setPhoneFixe]=useState(cookies.phone_fixe)
    const [fall_number,setFallNumber]=useState(cookies.fall_number)
    const [establishment_number,setEsblishment_number]=useState(cookies.establishment_number)
    const [notes,setNotes]=useState([])
    const [media,setMedia]=useState([])
    const[twiter,setTwiter]=useState('')
    const[snapchat,setSnapchat]=useState('')
    const[facebook,setFacebook]=useState('')
    const[instgram,setInstgram]=useState('')
    const[tiktok,setTikok]=useState('')
    const[whatsapp,setWhatsapp]=useState('')


    useEffect(() => {
     const id_user=cookies.id
     const email=cookies.email
      axios.post(`${BASE_URL_2}/users/note/getAllByUser`,{id_user})
    .then((response) => {
      const result = response.data
   
      setNotes(result.notes)
    
    })
    axios.post(`${BASE_URL_2}/users/media/get`,{email})
    .then((response) => {
      const result = response.data
    
      setMedia(result.media)
      setFacebook(result.media.facebook)
       setInstgram(result.media.instagram)
      setWhatsapp(result.media.whatapp)
      setTikok(result.media.tiktok)
      setTwiter(result.media.twiter)
      setSnapchat(result.media.snapchat) 
    })
    
    }, [])
    
    const notify = (text) => toast.success( text,{position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored", 
    fontFamilly:"Cairo"
  });
  const notify2 = (text) => toast.error( text,{position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored", 
  fontFamilly:"Cairo"
  });
  


      const update = async ()=>{
        const email=cookies.email
        const id_office=cookies.id_office
        if(cookies.isOffice==true){
          axios.post(`${BASE_URL_2}/users/office/update`,{name,email,password,phone_01,phone_02,phone_fixe,address,fall_number,establishment_number,id_office})
          .then((response) => {
            const result = response.data
            if(result.succes){
              setCookie('password', password, { path: '/', expires: new Date(Date.now() + 86400000) });
              setCookie('name', name, { path: '/', expires: new Date(Date.now() + 86400000) });
              setCookie('phone_01', phone_01, { path: '/', expires: new Date(Date.now() + 86400000) });
              setCookie('phone_02', phone_02, { path: '/', expires: new Date(Date.now() + 86400000) });
              setCookie('phone_fixe', phone_fixe, { path: '/', expires: new Date(Date.now() + 86400000) });
              setCookie('fall_number', fall_number, { path: '/', expires: new Date(Date.now() + 86400000) });
              setCookie('establishment_number', establishment_number, { path: '/', expires: new Date(Date.now() + 86400000) });
              setCookie('address',address, { path: '/', expires: new Date(Date.now() + 86400000) });
                onClose1()
                notify("تم تعديل معلومات الحساب بنجاح")
              
              
          
            }
            else{
              onClose1()
              notify2(result.message)
            }
          
          })
        }
        else{
          axios.post(`${BASE_URL_2}/users/user/update`,{name,email,password,phone,address,fall_number})
          .then((response) => {
            const result = response.data
            if(result.succes){
              setCookie('password', password, { path: '/', expires: new Date(Date.now() + 86400000) });
              setCookie('name', name, { path: '/', expires: new Date(Date.now() + 86400000) });
              setCookie('phone', phone, { path: '/', expires: new Date(Date.now() + 86400000) });
              setCookie('address',address, { path: '/', expires: new Date(Date.now() + 86400000) });
              setCookie('fall_number', fall_number, { path: '/', expires: new Date(Date.now() + 86400000) });
              onClose1()
              notify("تم تعديل معلومات الحساب بنجاح")
            }
            else{
              onClose1()
              notify2(result.message)
              
            }
          
          })
        }
       
      
      
      }
    
  const update2 =async()=>{
    const email=cookies.email
    axios.post(`${BASE_URL_2}/users/media/update`,{instgram,facebook,twiter,snapchat,tiktok,whatsapp,email})
    .then((response) => {
      axios.post(`${BASE_URL_2}/users/media/get`,{email})
      .then((response) => {
        const result = response.data
        setMedia(result.media)
        setFacebook(result.media.facebook)
       setInstgram(result.media.instagram)
      setWhatsapp(result.media.whatapp)
      setTikok(result.media.tiktok)
      setTwiter(result.media.twiter)
      setSnapchat(result.media.snapchat) 
        onClose3()
        notify("تم تعديل معلومات التواصل بنجاح")
        
      })
    })

    
  }
  return (
    <>
       <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <SimpleGrid
      mb='20px'
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}>
         <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
     
        <Flex px='25px' justify='space-between' mb='50px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
       معلومات الحساب
        </Text>
        <Button  
       backgroundColor={bgButton}  
        borderRadius='10px'   
        color={iconColor}  
        onClick={()=>{
        setOverlay(<OverlayOne />)
        onOpen1()
        }}
      
        > تعديل معلومات الحساب <Icon as={MdOutlineEdit} width='20px' mr='5px' height='20px' color={iconColor} /></Button>
        </Flex>
        <SimpleGrid columns={{sm:1, md:2}} spacing={10}>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          lineHeight='100%' 
          
        >
        الاسم
        </Text>
        <Text
          color={textColor}
          
          fontWeight='700'
          lineHeight='100%' 
        >
        {cookies.name}
        </Text>
        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
        
          
          lineHeight='100%' 
        >
        البريد الالكتروني
        </Text>
        <Text
          color={textColor}
          
          fontWeight='700'
          lineHeight='100%' 
        >
         {cookies.email}
        </Text>
        
        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
        
          
          lineHeight='100%' 
        >
         الباقة 
        </Text>
        <Text
          color={textColor}
          
          fontWeight='700'
          lineHeight='100%' 
        >
         {cookies.pricing}
        </Text>
        
        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
        
          
          lineHeight='100%' 
        >
         تاريخ التفعيل
        </Text>
        <Text
          color={textColor}
          fontWeight='700'
          lineHeight='100%' 
        > {(cookies.create_at).substring(0,10)}</Text>
       
       
        
        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
        
          
          lineHeight='100%' 
        >
          مدة  الاشتراك
        </Text>
        <Text
          color={textColor}
          
          fontWeight='700'
          lineHeight='100%' 
        >
         {(cookies.pricing)=="مجانية"?15:30}يوم
        </Text>
        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
        
          
          lineHeight='100%' 
        >
         العنوان
        </Text>
        <Text
          color={textColor}
          fontWeight='700'
          lineHeight='100%' 
        >
         {cookies.address}
        </Text>
        </Flex>
        {cookies.isOffice==true&& (
          <>
              <Flex px='25px' justify='space-between' mb='20px' align='center'>
              <Text
                color={textColor}
                lineHeight='100%' 
              >
               رقم الهاتف الاول
              </Text>
              <Text
               color={textColor}
                fontWeight='700'
                lineHeight='100%' 
              >
               {cookies.phone_01}
              </Text>
              </Flex>
               <Flex px='25px' justify='space-between' mb='20px' align='center'>
               <Text
                 color={textColor}
                 lineHeight='100%' 
               >
                رقم الهاتف الثاني
               </Text>
               <Text
                 color={textColor}
                 fontWeight='700'
                 lineHeight='100%' 
               >
                {cookies.phone_02}
               </Text>
               </Flex>

               <Flex px='25px' justify='space-between' mb='20px' align='center'>
               <Text
                 color={textColor}
                 lineHeight='100%' 
               >
                رقم الهاتف الثابت
               </Text>
               <Text
                color={textColor}
                 fontWeight='700'
                 lineHeight='100%' 
               >
                {cookies.phone_fixe}
               </Text>
               </Flex>
               <Flex px='25px' justify='space-between' mb='20px' align='center'>
               <Text
                 color={textColor}
                 lineHeight='100%' 
               >
                رقم رخصة فال
               </Text>
               <Text
                 color={textColor}
                 fontWeight='700'
                 lineHeight='100%' 
               >
                {cookies.fall_number}
               </Text>
               </Flex>
               <Flex px='25px' justify='space-between' mb='20px' align='center'>
               <Text
                 color={textColor}
                 lineHeight='100%' 
               >
                رقم المكتب
               </Text>
               <Text
                 color={textColor}
                 fontWeight='700'
                 lineHeight='100%' 
               >
                {cookies.establishment_number}
               </Text>
               </Flex>
               
               </>
        )}
         {cookies.isOffice==false &&(
          <>     <Flex px='25px' justify='space-between' mb='20px' align='center'>
          <Text
            color={textColor}
            lineHeight='100%' 
          >
           رقم رخصة فال
          </Text>
          <Text
            color={textColor}
            fontWeight='700'
            lineHeight='100%' 
          >
           {cookies.fall_number}
          </Text>
          </Flex>
              <Flex px='25px' justify='space-between' mb='20px' align='center'>
              <Text
                color={textColor}
                lineHeight='100%' 
              >
               رقم الهاتف 
              </Text>
              <Text
               color={textColor}
                fontWeight='700'
                lineHeight='100%' 
              >
               {cookies.phone}
              </Text>
              </Flex>
          
         
               
               </>
        )}   {cookies.isOffice==false &&cookies.pricing!="مجانية" &&(
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
              <Text
                color={textColor}
                lineHeight='100%' 
              >
                الصفة 
              </Text>
              <Text
               color={textColor}
                fontWeight='700'
                lineHeight='100%' 
              >
               {cookies.type}
              </Text>
              </Flex>)}
        </SimpleGrid>
        </Card>


        <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
     
        <Flex px='25px' justify='space-between' mb='50px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
     مواقع التواصل الاجتماعي
        </Text>
        <Button  
       backgroundColor={bgButton}  
        borderRadius='10px'   
        color={iconColor}  
        onClick={()=>{
        setOverlay(<OverlayOne />)
        onOpen3()
        }}
      
        >  تعديل المعلومات التواصل <Icon as={MdOutlineEdit} width='20px' mr='5px' height='20px' color={iconColor} /></Button>
        </Flex>
        <SimpleGrid columns={{sm:1, md:2}} spacing={10}>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          lineHeight='100%' 
         
        >
        <SocialIcon url={twiter?twiter:"https://twitter.com"} />
        </Text>
        <Text
          color={textColor}
          
          fontWeight='700'
          lineHeight='100%' 
        >
        {twiter?twiter:"لم يتم تحديده بعد"}
        </Text>
        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
        
          
          lineHeight='100%' 
        >
        <SocialIcon url={whatsapp?whatsapp:"https://whatsapp.com"} />
        </Text>
        <Text
          color={textColor}
          
          fontWeight='700'
          lineHeight='100%' 
        >
         {whatsapp?whatsapp:"لم يتم تحديده بعد"}
        </Text>
        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
        
          
          lineHeight='100%' 
        >
         <SocialIcon url={facebook?facebook:"https://facebook.com"} />
        </Text>
        <Text
          color={textColor}
          fontWeight='700'
          lineHeight='100%' 
        >
          {facebook?facebook:"لم يتم تحديده بعد"}
        </Text>
        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
        
          
          lineHeight='100%' 
        >
         <SocialIcon url=  {instgram?instgram:"https://instagram.com"} />
        </Text>
        <Text
          color={textColor}
          fontWeight='700'
          lineHeight='100%' 
        >
         {instgram?instgram:"لم يتم تحديده بعد"}
        </Text>
        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
        
          
          lineHeight='100%' 
        >
         <SocialIcon url={snapchat?snapchat:"https://snapchat.com"} />
        </Text>
        <Text
          color={textColor}
          fontWeight='700'
          lineHeight='100%' 
        >
         {snapchat?snapchat:"لم يتم تحديده بعد"}
        </Text>
        </Flex>

        
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
        
          
          lineHeight='100%' 
        >
         <SocialIcon url={tiktok?tiktok:"https://tiktok.com"}  />
        </Text>
        <Text
          color={textColor}
          fontWeight='700'
          lineHeight='100%' 
        >
         {tiktok?tiktok:"لم يتم تحديده بعد"}
        </Text>
        </Flex>

        
        
        
        
        </SimpleGrid>
        </Card>


        {cookies.isOffice==false && notes.length?
                <Card
                direction='column'
                w='100%'
                px='0px'
                overflowX={{ sm: "scroll", lg: "hidden" }}>
               
                  <Flex px='25px' justify='space-between' mb='50px' align='center'>
                  <Text
                    color={textColor}
                    fontSize='22px'
                    fontWeight='700'
                    lineHeight='100%' 
                  >
                  ملاحظات صاحب المكتب
                  </Text>
         
                  </Flex>
                  <SimpleGrid columns={{sm:1, md:2}} spacing={10}>
                
                    {
                      notes.map((item,index)=>{
                        return(
                          <>
                    <Flex px='25px' justify='space-between' mb='20px' align='center'>
                           <Text
                    color={textColor}
                    fontWeight='700'
                    
                    lineHeight='100%' 
                  >
                  الملاحظة {index +1}
                  </Text>
                  <Text
                    color={textColor}
                    
                   
                    lineHeight='100%' 
                  >
                  {item.text}
                  </Text>
                  </Flex>
                          </>
                        )
                      })
                    }
                  
                 
                         
                         
                  
                  
                  </SimpleGrid>
                  </Card>:""
        }
        </SimpleGrid>
        </Box>
        <Modal isCentered    isOpen={isOpen1} onClose={onClose1}>
        {overlay}
        <ModalContent textAlign="center">
          <ModalHeader> تعديل معلومات  الحساب </ModalHeader>
         
          <ModalCloseButton />
          <ModalBody>
          
          <FormControl>
              <FormLabel> الاسم</FormLabel>
              <Input  value={name}  onChange={(event)=>{setName(event.target.value)}}/>
            </FormControl>
            <FormControl>
              <FormLabel> العنوان</FormLabel>
              <Input  value={address}  onChange={(event)=>{setAddresss(event.target.value)}}/>
            </FormControl>
              {cookies.isOffice==true &&
              <>
              <FormControl>
              <FormLabel>  رقم الهاتف الاول</FormLabel>
              <PhoneInput
              defaultCountry="sa"
              textAlign="left"
              value={phone_01}
             onChange={(phone) => setPhone01(phone)}
          />
            </FormControl>
            <FormControl>
              <FormLabel>  رقم الهاتف الثاني</FormLabel>
              <PhoneInput
              defaultCountry="sa"
              textAlign="left"
              value={phone_02}
             onChange={(phone) => setPhone02(phone)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>  رقم الهاتف الثابت</FormLabel>
              <PhoneInput
              defaultCountry="sa"
              textAlign="left"
              value={phone_fixe}
             onChange={(phone) => setPhoneFixe(phone)}
          />
            </FormControl>
            <FormControl>
              <FormLabel>  رقم رخصة فال</FormLabel>
              <Input  value={fall_number}  onChange={(event)=>{setFallNumber(event.target.value)}}/>
            </FormControl>
            <FormControl>
              <FormLabel>  رقم المكتب</FormLabel>
              <Input  value={establishment_number}  onChange={(event)=>{setEsblishment_number(event.target.value)}}/>
            </FormControl>
              </>}
              {cookies.isOffice==false &&
              <>
                 <FormControl>
                 <FormLabel>  رقم رخصة فال</FormLabel>
                 <Input  value={fall_number}  onChange={(event)=>{setFallNumber(event.target.value)}}/>
               </FormControl>
               <FormControl>
               <FormLabel>  رقم الهاتف</FormLabel>
               <PhoneInput
              defaultCountry="sa"
              textAlign="left"
              value={phone}
             onChange={(phone) => setPhone(phone)}
          />
              
             </FormControl>
             </>
              }
            
          </ModalBody>

          
         
      
          <ModalFooter>
            {cookies.isOffice==true && <Button   disabled={name&&password&&address&&phone_01&&fall_number&&establishment_number?false:true}  colorScheme='blue' mr={3}  onClick={update}>    تعديل
            </Button> }
            {cookies.isOffice==false && <Button   disabled={name&&password&&address&&phone?false:true}  colorScheme='blue' mr={3}  onClick={update}>    تعديل
            </Button> }
           
            <Button  onClick={onClose1}>اغلاق</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Modal isCentered    isOpen={isOpen3} onClose={onClose3}>
        {overlay}
        <ModalContent textAlign="center">
          <ModalHeader> تعديل معلومات  التواصل </ModalHeader>
         
          <ModalCloseButton />
          <ModalBody>
          
          <FormControl display='flex' flexDirection='row'>
              <FormLabel textAlign='center'><SocialIcon style={{width:'50px'}} url="https://twitter.com" /></FormLabel>
              <Input  value={twiter}  onChange={(event)=>{setTwiter(event.target.value)}}/>
            </FormControl>
            <FormControl display='flex' flexDirection='row'>
              <FormLabel  textAlign='center'>    <SocialIcon url="https://whatsapp.com" /></FormLabel>
              <Input  value={whatsapp}  onChange={(event)=>{setWhatsapp(event.target.value)}}/>
            
          
            </FormControl>
            <FormControl display='flex' flexDirection='row'>
              <FormLabel textAlign='center' ><SocialIcon url="https://facebook.com" /></FormLabel>
              <Input   value={facebook}  onChange={(event)=>{setFacebook(event.target.value)}}/>
            </FormControl>
              <FormControl display='flex' flexDirection='row'>
              <FormLabel textAlign='center'> <SocialIcon url="https://instagram.com" /></FormLabel>
              <Input  value={instgram}  onChange={(event)=>{setInstgram(event.target.value)}}/>
            </FormControl>
            <FormControl display='flex' flexDirection='row'>
              <FormLabel textAlign='center'><SocialIcon url="https://snapchat.com" />  </FormLabel>
              <Input  value={snapchat}  onChange={(event)=>{setSnapchat(event.target.value)}}/>
            </FormControl>
            <FormControl display='flex' flexDirection='row'>
              <FormLabel textAlign='center'> <SocialIcon url="https://tiktok.com" /></FormLabel>
              <Input  value={tiktok}  onChange={(event)=>{setTikok(event.target.value)}}/>
            </FormControl>
        
              
           
            
          </ModalBody>

          
         
      
          <ModalFooter>
           
           
         
          <Button  colorScheme='blue' mr={3}  onClick={update2}>تعديل</Button> 
           
            <Button  onClick={onClose3}>اغلاق</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ToastContainer  position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"/>
    </>
  )
}

