import React,{useState,useEffect} from 'react'

import {
  Box,SimpleGrid,Flex,Text, useColorModeValue,Icon,Button  ,Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Image,
  Select,
  InputLeftAddon,InputGroup,
  ModalCloseButton,FormLabel,Input,Link
} from '@chakra-ui/react'
import { ToastContainer, toast } from 'react-toastify';
import L from 'leaflet';
import {DropzoneArea} from 'material-ui-dropzone'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useCookies } from 'react-cookie';

import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { IoIosAddCircle } from 'react-icons/io';
import { useDisclosure } from '@chakra-ui/hooks';
import 'react-international-phone/style.css';
import Card from "components/card/Card";
import { MdOutlineFileDownloadDone} from 'react-icons/md'
import ReactPlayer from 'react-player';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BiShare } from 'react-icons/bi';
import { BASE_URL_2 } from 'config';
import { BASE_URL_1 } from 'config';
import ReactLoading from 'react-loading';
export default function  ProposalDetails ()  {
   const notify = (text) => toast.success( text,{position: "bottom-right",
   autoClose: 5000,
   hideProgressBar: false,
   closeOnClick: true,
   pauseOnHover: true,
   draggable: true,
   progress: undefined,
   theme: "colored", 
   fontFamilly:"Cairo"
 });
 
const [loading,setLoading]=useState(true)
   const [copied, setCopied] = useState(false);
   const handleCopyClick = () => {
      const imageUrls = fichies
      .filter(item => item.type === 'image')
      .map(item => item.url);
      const vediosUrl = fichies
      .filter(item => item.type === 'video')
      .map(item => item.url);
 
      const markerLabel = encodeURI('موقع العقار'); // Encode the custom label for URL
    const googleMapsLink = `https://www.google.com/maps/place/${proposal.latitude},${proposal.longitude}`;
    const realEstateInfo  = {"معلومات العقار": {
      "معلومات المستخدم": `https://maktabi-0.com/maktabi/media/${proposal.id_user}`,
      "اسم المستخدم": proposal.user_name,
      "نوع المستخدم": proposal.user_type,
      "نوع العقار": proposal.type,
      "المدينة": proposal.city,
      "الحي": proposal.town,
      "نوع العرض": proposal.categorie,
      "حالة العقار": proposal.status,
      "مساحة العقار": `${proposal.surface} (متر²)`,
      "سعر العقار": `${proposal.price} (ر.س)`,
      "عمر العقار": `${proposal.age} (سنة)`,
      " رقم رخصة الاعلان": `${proposal.fall_number}`,
      ...(proposal.landline_number && {"رقم الأرض": proposal.landline_number}),
      ...(proposal.plan_number && {"رقم المخطط": proposal.plan_number}),
      ...(proposal.plan_name && {"اسم المخطط": proposal.plan_name}),
      ...(proposal.facade && {"الواجهة": proposal.facade}),
      ...(proposal.street_width && {"عرض الشارع": proposal.street_width}),
      ...(proposal.height && {"طول العقار": proposal.height}),
      ...(proposal.width && {"عرض العقار": proposal.width}),
      ...(proposal.high && {"ارتفاع العقار": proposal.high}),
      ...(proposal.floors_number && {"عدد الأدوار": proposal.floors_number}),
      ...(proposal.apartments_number && {"عدد الشقق": proposal.apartments_number}),
      ...(proposal.rooms_number && {"عدد الغرف": proposal.rooms_number}),
      ...(proposal.bathrooms_number && {"عدد دورات المياة": proposal.bathrooms_number}),
      ...(imageUrls.length &&  { "صور العقار": imageUrls}),
      ...(vediosUrl.length &&  { "فيديوهات العقار": vediosUrl}),
      "موقع العقار (Google Maps)": googleMapsLink,}
    };
     const copiedText=JSON.stringify(realEstateInfo, null, 2).replace(/[{}"]/g, '') // Remove {, }, and " characters
     .replace(/^\s+/gm, ''); // Remove leading spaces from each line
    
 
     const trimmedText=copiedText.replace(/\n\s*\n/g, '\n');
     navigator.clipboard.writeText(trimmedText)
     setCopied(true);
     notify( 'تم نسخ معلومات العقار,يمكنك الان مشاركته')
     setMessage('تم نسخ المعلومات بنجاح.');
     setTimeout(() => {
       setMessage('');
       setCopied(false);
     }, 5000);
   }
  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
  )

const iconColor = useColorModeValue("brand.500", "white");
const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [overlay, setOverlay] = useState(<OverlayOne />)
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const [cookies, setCookie, removeCookie] = useCookies(['name','id_real_estate','id','type','id_office','password', 'email','token','isOffice','phone','phone_01','phone_02','phone_fixe','fall_number','establishment_number','address']);
 
    const [images,setImages]=useState([])
   const [vedios,setVeduis]=useState([])
  
   const [message,setMessage]=useState('')
const [proposal,setProposal]=useState('')
const [fichies,setFichies]=useState([])

    useEffect(() => {
        const id_real_estate=cookies.id_real_estate
        axios.post(`${BASE_URL_2}/real_estates/getById`,{id_real_estate})
        .then((response) => {
          const result = response.data
         
          setProposal(result.real_estate)
          setLoading(false)
        
        })

        axios.post(`${BASE_URL_2}/real_estates/file/get`,{id_real_estate})
        .then((response) => {
          const result = response.data
         
          setFichies(result.fichies)
        })
          
       
          
    
    
    }, [])


    const [latitude, setLatitude] = useState(24.7136);
    const [longitude, setLongitude] = useState(46.6753);
    
    const customMarkerIcon2 = new L.Icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
        shadowSize: [41, 41]
      });
 

    

      
    
return ( <> 
         {loading==true?   <div style={{
           display: 'flex',
           alignItems: 'center',
           justifyContent: 'center',
           minHeight: '100vh', // Set minimum height to fill the viewport
       
         }}> <ReactLoading  type="spin" color="#422AFB" height={100} width={80} /></div>
        :( <>
       <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <SimpleGrid
      mb='10px'
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}>
         <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
     
        <Flex px='25px' justify='space-between' mb='50px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
        معلومات العقار
        </Text>
       
        <Button  
     
       backgroundColor={bgButton}  
        borderRadius='10px'   
        color={iconColor}  
        type='submit'
        onClick={handleCopyClick}
      
        >  مشاركة معلومات العرض <Icon as={BiShare} width='20px' mr='5px' height='20px' color={iconColor} /></Button>
       
       </Flex>
        <SimpleGrid columns={{sm:1, md:3}} spacing={10}>
        {proposal.id_office!=-1 ?
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600' >رقم المكتب:</FormLabel>
            <FormLabel  >{proposal.id_office}</FormLabel>
       

        </Flex>:""}
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600' > اسم المستخدم:</FormLabel>
            <FormLabel  >{proposal.user_name}</FormLabel>
           

        </Flex> 
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600' > رقم هاتف المستخدم:</FormLabel>
            <FormLabel  >{proposal.user_phone}</FormLabel>
      
        </Flex>
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600' > ايمايل المستخدم:</FormLabel>
            <FormLabel  >{proposal.email}</FormLabel>
      
        </Flex>
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'>نوع العقار : </FormLabel>
           <FormLabel>{proposal.type}</FormLabel>
        

        </Flex>

        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'>المدينة :</FormLabel>
           <FormLabel >{proposal.city}</FormLabel>
       

        </Flex>
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'> الحي :</FormLabel>
           <FormLabel >{proposal.town}</FormLabel>
        

        </Flex>
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600' > نوع العرض :</FormLabel>
           <FormLabel >{proposal.categorie}</FormLabel>
        
        </Flex>

        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel  fontWeight='600'>حالة العقار :</FormLabel>
           <FormLabel>{proposal.status}</FormLabel>
        

        </Flex>

        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'>مساحة العقار :</FormLabel>
           <FormLabel>{proposal.surface} (متر²)</FormLabel>
          
        

        </Flex>
       
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'>سعر العقار :</FormLabel>
           <FormLabel >{proposal.price}(ر.س) </FormLabel>
        
         
        </Flex>
        
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'>عمر العقار :</FormLabel>
           <FormLabel   >{proposal.age}  (سنة)</FormLabel>  
        </Flex>
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'> رقم رخصة الاعلان :</FormLabel>
           <FormLabel>{proposal.fall_number} </FormLabel>  
        </Flex>
       
      
       
        {proposal.landline_number? 
           <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'>رقم الأرض :</FormLabel>
           <FormLabel >{proposal.landline_number}</FormLabel>
       
        </Flex>: ""}
        {proposal.landline_number ?
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'> رقم المخطط :</FormLabel>
           <FormLabel >{proposal.plan_number}  </FormLabel>
        

        </Flex>
        :""}
        {proposal.plan_name ?
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'> اسم المخطط :</FormLabel>
           <FormLabel>{proposal.plan_name} </FormLabel>
         
         

        </Flex>:""}
        {proposal.facade ?
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'> الواجهة  :</FormLabel>
           <FormLabel > {proposal.facade} </FormLabel>
       

        </Flex>:""}
        {proposal.price_per_meter ?
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'> الواجهة  :</FormLabel>
           <FormLabel > {proposal.price_per_meter} </FormLabel>

        </Flex>:""}
        
        {proposal.street_width ?
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel  fontWeight='600'>عرض الشارع :</FormLabel>
           <FormLabel  >{proposal.street_width} </FormLabel>
       

        </Flex>:""}
        {proposal.height ?
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'>طول العقار :</FormLabel>
           <FormLabel  >{proposal.height} </FormLabel>
        

        </Flex>:""}
        {proposal.width ?
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel  fontWeight='600'>عرض العقار :</FormLabel>
           <FormLabel  >{proposal.width}</FormLabel>
          

        </Flex>:""}
        {proposal.high?
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel  fontWeight='600'>ارتفاع العقار :</FormLabel>
           <FormLabel  > {proposal.high} </FormLabel>
        </Flex>:""}
        {proposal.floors_number ?
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'>عدد الأدوار :</FormLabel>
           <FormLabel  > {proposal.floors_number} </FormLabel>
         

        </Flex>:""}
        {proposal.apartments_number ?
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'>عدد الشقق :</FormLabel>
           <FormLabel >{proposal.apartments_number} </FormLabel>
         

        </Flex>:""}
        {proposal.rooms_number ?
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel  fontWeight='600'>عدد الغرف :</FormLabel>
           <FormLabel  >{proposal.rooms_number}</FormLabel>
           
          

        </Flex>:""}
        {proposal.bathrooms_number ?
        <Flex px='25px' justify='space-between' mb='10px' align='center'>
           <FormLabel  fontWeight='600'>عدد دورات المياة :</FormLabel>
           <FormLabel  >{proposal.bathrooms_number}</FormLabel>
          

        </Flex>:""}
       
     
       
       
         
        </SimpleGrid>
       
        </Card>
        <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>

<Flex px='25px' justify='spance-betweenszes' mb='50px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
            موقع العقار
        </Text>
       </Flex>
       <SimpleGrid columns={{sm:1, md:2}} spacing={10}>

    
       </SimpleGrid>
       <MapContainer  center={[proposal.latitude?proposal.latitude:latitude, proposal.longitude?proposal.longitude:longitude]}  zoom = '8' style={{ height: '400px', width: '100%' }}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker  icon={customMarkerIcon2} position={[proposal.latitude?proposal.latitude:latitude, proposal.longitude?proposal.longitude:longitude]} draggable={false} >
          <Popup> (موقع العقار: {proposal.latitude} , {proposal.longitude})</Popup>
        </Marker>
      </MapContainer>
      </Card>
     
     {(fichies.length )?
      <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>

<Flex px='25px' justify='space-between' mb='50px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
            الصور و الفيديوهات
        </Text>
       </Flex>
       <SimpleGrid columns={{sm:1, md:1}} spacing={10}>
       {fichies.some(item => item.type === "image")?
         <>
       <Flex px='25px' flexDirection='row' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'>  الصور</FormLabel>
           </Flex>
        <Flex px='25px' flexDirection='row' justify='space-between' mb='10px' align='center'>
           <SimpleGrid columns={{sm:1, md:3}} spacing={10}>
            {fichies.map((item,index)=>{
                if(item.type=='image'){
                    return(
                        <Image width="100%" height="400" src={item.url}/>
                    )
                }
            })}
           </SimpleGrid>
          
        </Flex></>:""}
       {fichies.some(item => item.type === "video")?
       <>
        <Flex px='25px' flexDirection='row' justify='space-between' mb='10px' align='center'>
           <FormLabel fontWeight='600'> الفيديوهات</FormLabel>
        </Flex>
        
           <SimpleGrid columns={{sm:1, md:2}} spacing={10}>
           {fichies.map((item,index)=>{
                if(item.type=='video'){
                    return(
                        <>
                        <ReactPlayer url={item.url} controls width="100%" height="100%" />
                     </>)
                }
            })}
       
           </SimpleGrid>
           </>:""} 
     
       </SimpleGrid>
    
      </Card>:""}
        </SimpleGrid>
        </Box>
       
   
        <Modal closeOnOverlayClick={false}  isCentered    isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent textAlign="center">
          <ModalHeader>عملية ناجحة</ModalHeader>
          <Icon as={MdOutlineFileDownloadDone} width='50px' height='50px' color='green' margin="auto" />
          <ModalCloseButton />
          <ModalBody>
            <Text>{message}</Text>
          </ModalBody>
          <ModalFooter>
            <Link href='/dashboard/proposales'>
            <Button  onClick={onClose}>اغلاق</Button></Link>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ToastContainer  position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"/>
    </>)}</>
  )
}

