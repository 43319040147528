import React,{useState,useEffect} from 'react'

import {
  Box,SimpleGrid,Flex,Text, useColorModeValue,Icon,Button  ,Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  InputLeftAddon,InputGroup,
  ModalCloseButton,FormLabel,Input,Link
} from '@chakra-ui/react'
import {DropzoneArea} from 'material-ui-dropzone'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useCookies } from 'react-cookie';
import L from 'leaflet';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { IoIosAddCircle } from 'react-icons/io';
import { useDisclosure } from '@chakra-ui/hooks';
import 'react-international-phone/style.css';
import Card from "components/card/Card";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from './firebaseConfig';
import { MdOutlineFileDownloadDone} from 'react-icons/md'
import ReactLoading from 'react-loading';
import { BiErrorCircle } from 'react-icons/bi';
import { BASE_URL_2 } from 'config';
import { NumericFormat } from 'react-number-format';
export default function  ProposalAdd ()  {
  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
  )
  const customMarkerIcon2 = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [41, 41]
  });
  const [loading,setLoading]=useState(false)
const iconColor = useColorModeValue("brand.500", "white");
const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure(); // First modal
const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  const [overlay, setOverlay] = useState(<OverlayOne />)
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const [cookies, setCookie, removeCookie] = useCookies(['name','id_real_estate','id','type','id_office','password', 'email','token','isOffice','phone','phone_01','phone_02','phone_fixe','fall_number','establishment_number','address']);
   const [users,setUsers]=useState([])
   const [user_type,setUsrType]=useState('')
   const[types,setTypes]=useState([])
   const [towns,setTowns]=useState([])
   const [citys,setCitys]=useState([])
   const [town,setTown]=useState('')
    const [images,setImages]=useState([])
   const[show,setShow]=useState(false)
   const [vedios,setVeduis]=useState([])
   const[id_user,setIdUser]=useState(cookies.id)
   const[user_name,setUserName]=useState(cookies.isOffice==true?'':cookies.name)
   const[user_phone,setPhone]=useState(cookies.phone)
   const [categorie,setCategorie]=useState('')
   const [email,setEmail]=useState(cookies.email)
   const [type,setType]=useState('')
   const [status,setStatus]=useState('')
   const[age,setAge]=useState('')
   const [city,setCity]=useState('')
   const [landline_number,setLandline]=useState('')
   const [surface,setSurface]=useState('')
   const [plan_number,setPlan_number]=useState('')
   const [plan_name,setPlanName]=useState('')
   const [facade,setFacade]=useState('')
   const [street_width,setStreetWidth]=useState('')
   const [price_per_meter,setPricePerMeter]=useState('')
   const [height,setHeigt]=useState('')
   const [width,setWidth]=useState('')
   const [high,setHigh]=useState('')
   const [floors_number,setFloors]=useState('')
   const [apartments_number,setApartments]=useState('')
   const [rooms_number,setRooms]=useState('')
   const [bathrooms_number,setBathrooms]=useState('')
   const[price,setPrice]=useState('')
   const[min_price,setMinPrice]=useState('')
   const[max_price,setMaxPrice]=useState('')
   const [message,setMessage]=useState('')
   const [fall_number,setFallNumber]=useState('')

    useEffect(() => {
        const id_office= cookies.id_office
        axios.post(`${BASE_URL_2}/users/user/getAllByOffice`,{id_office})
          .then((response) => {
            const result = response.data

            setUsers(result.users)
          
          })
          axios.post(`${BASE_URL_2}/type/getAll`)
          .then((response) => {
            const result = response.data

            setTypes(result.types)

       
            axios.post(`${BASE_URL_2}/town/getAll`)
            .then((response) => {
              const result = response.data
              setTowns(result.towns)
            
            })
          
          })
          
    
    
    }, [])

  
    useEffect(() => {
        axios.post(`${BASE_URL_2}/city/getByTown`,{town})
        .then((response) => {
          const result = response.data
          setCitys(result.city)
        
        })
    
    }, [town])

    const [position, setPosition] = useState([24.7136,46.6753]); // Default position
    const [latitude, setLatitude] = useState(24.7136);
    const [longitude, setLongitude] = useState(46.6753);
    const [imageUrls, setImageUrls] = useState([]);
    const [videoUrls, setVideoUrls] = useState([]);
    const [progresspercent, setProgresspercent] = useState(0);
    const handleSubmit = async (e) => {
      setLoading(true)
      e.preventDefault();
      const uploadFile = async (file, storagePath) => {
        const storageRef = ref(storage, storagePath);
        const uploadTask = uploadBytesResumable(storageRef, file);
        return new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              setProgresspercent(progress);
            },
            (error) => {
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            }
          );
        });
      };
    
      try {
        const uploadedImagesUrls = await Promise.all(
          images.map((file) => uploadFile(file, `images/${file.name}`))
        );
    
        const uploadedVideosUrls = await Promise.all(
          vedios.map((file) => uploadFile(file, `videos/${file.name}`))
        );
    
      
        const id_office = cookies.id_office;
        const response = await axios.post(`${BASE_URL_2}/real_estates/proposal/add`,{id_user,user_name,email,user_phone,id_office, categorie, type, status, age, town,
        city, landline_number, surface, plan_name, plan_number, facade, street_width,
        price_per_meter, width, height,high, floors_number, apartments_number, rooms_number,
        bathrooms_number, price, min_price, max_price, longitude, latitude,user_type,fall_number});
    
        const result = response.data;
        if(result.message){
          setMessage(result.message);
          setLoading(false)
          setOverlay(<OverlayOne />);
          onOpen2();
        } 
        else{
        
        const id = result.id;
       
        await Promise.all(
          uploadedImagesUrls.map((url) =>
            axios.post(`${BASE_URL_2}/real_estates/file/add`, { id, type: "image", url })
          )
        );
    
        await Promise.all(
          uploadedVideosUrls.map((url) =>
            axios.post(`${BASE_URL_2}/real_estates/file/add`, { id, type: "video", url })
          )
        );
    
        setMessage('تم اضافة العرض بنجاح');
        setLoading(false)
        setOverlay(<OverlayOne />);
        onOpen1();
      } }catch (error) {
        // Handle errors
        console.error(error);
      }
    };
    

    
    const handleMarkerDragEnd = (e) => {
        const marker = e.target;
        const markerPosition = marker.getLatLng();
    
        setLatitude(markerPosition.lat);
        setLongitude(markerPosition.lng);
      };
    
    
  

  const handleChange1 = (newFiles) => {
    setImages(newFiles);
  };
  const handleChange2 = (newFiles) => {
setVeduis(newFiles)  };
  const handleLatitudeChange = () => {
    
    setPosition([latitude, longitude]);
  };

  const handleLongitudeChange = (e) => {
    
    setPosition([latitude, longitude]);
  };


      
    
  return (
    <> 
    {loading==true?   <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh', // Set minimum height to fill the viewport
 
    }}> <ReactLoading  type="spin" color="#422AFB" height={100} width={80} /></div>
   :(
    <>
       <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <SimpleGrid
      mb='20px'
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}>
         <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
     
        <Flex px='25px' justify='space-between' mb='50px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
         ادخل معلومات العقار
        </Text>
        <Button  
       isDisabled={user_name&&user_type&&fall_number&&city&&town&&type&&categorie&&status&&age&&price?false:true}
       backgroundColor={bgButton}  
        borderRadius='10px'   
        color={iconColor}  
        type='submit'
        onClick={handleSubmit}
      
        >  اضافة عرض <Icon as={IoIosAddCircle} width='20px' mr='5px' height='20px' color={iconColor} /></Button>
       </Flex>
        <SimpleGrid columns={{sm:1, md:2}} spacing={10}>
        {cookies.isOffice==true &&
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'> اسم المستخدم</FormLabel>
           <Select
            width='400px'
            placeholder='اختر المستخدم'
            onChange={(e) => {
              const selectedUser = users.find(user => user.name === e.target.value);
              if (selectedUser) {
                setIdUser(selectedUser.id_user)
                setUserName(selectedUser.name);
                setEmail(selectedUser.email);
                setPhone(selectedUser.phone);
              }
            }}
          >
            {users.map((item, index) => (
              <option key={index} value={item.name}>
                {item.name}
              </option>
            ))}
          </Select>

        </Flex> }
        {cookies.isOffice==false &&
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'> اسم المستخدم</FormLabel>
            <Input width='400px' value={cookies.name}></Input>

        </Flex> }
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'> نوع المستخدم</FormLabel>
        <Select width='400px' placeholder='اختر  نوع المستخدم' onChange={(e)=>{setUsrType(e.target.value)}} >
               
           
                  <option value='مالك' >مالك</option>
                  <option value='مسوق' >مسوق</option>
                  
               
        </Select>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>  نوع العقار</FormLabel>
        <Select width='400px' placeholder='اختر نوع العقار' onChange={(e)=>{setType(e.target.value)}} >
                {types.map((item)=>{
                  return(
                  <option value={item.name} >{item.name}</option>
                  )
                })}
        </Select>

        </Flex>

        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>   المدينة</FormLabel>
        <Select width='400px' placeholder='اختر  المدينة' onChange={(e)=>{
            setTown(e.target.value)

        }} >
                {towns.map((item)=>{
                  return(
                  <option value={item.name} >{item.name}</option>
                  )
                })}
        </Select>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'> الحي</FormLabel>
        <Select width='400px' placeholder='اختر  الحي' onChange={(e)=>{setCity(e.target.value)}} >
                {citys.map((item)=>{
                  return(
                  <option value={item.name} >{item.name}</option>
                  )
                })}
        </Select>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'> نوع العرض</FormLabel>
        <Select onChange={(e)=>{setCategorie(e.target.value)}}  width='400px' placeholder='اختر  نوع العرض'  >
            
                  <option value='استثمار' >استثمار</option>
                  <option value='ايجار' >ايجار</option>
                  <option value='بيع' >بيع</option>
             
        </Select>

        </Flex>

        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>  حالة العقار</FormLabel>
        <Select onChange={(e)=>{setStatus(e.target.value)}}  width='400px' placeholder='اختر  حاله العرض'  >
            
                  <option value='جديد' >جديد</option>
                  <option value='مستعمل' >مستعمل</option>
                
             
        </Select>

        </Flex>

        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600' >   مساحة العقار</FormLabel>
           <InputGroup width='400px'>
          <InputLeftAddon children='متر²' />
          <Input   onChange={(e)=>{setSurface(e.target.value)}}    ></Input>
        </InputGroup>
        

        </Flex>
       
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>   سعر العقار</FormLabel>
        
          <InputGroup width='400px'>
          <InputLeftAddon children='ر.س' />
          <NumericFormat
        thousandSeparator='.' decimalSeparator=','
        style={{ marginLeft: '5px' }}
        customInput={Input}
        onValueChange={(values, sourceInfo) => {
        setPrice(values.formattedValue)
        }}
      />
        </InputGroup>
        </Flex>
        
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'  >   عمر العقار</FormLabel>
      
          <InputGroup width='400px'>
          <InputLeftAddon children='سنة' />
          <Input onChange={(e)=>{setAge(e.target.value)}} ></Input>
        </InputGroup>
          
        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'  > رقم رخصة الاعلان</FormLabel>
      
       
          <Input width='400px' onChange={(e)=>{setFallNumber(e.target.value)}} ></Input>
  
          
        </Flex>
        {
          show==false&& (
          <Flex px='25px' justify='space-between' mb='20px' mt='20px'   align='center'>
                  <Button  
                  padding='10px 50px'
                backgroundColor={bgButton}  
                  borderRadius='10px'   
                  color={iconColor}  
                  onClick={()=>{
                    setShow(true)
                  }}
                
                  >  عرض الحقول الاختيارية  </Button></Flex>
          )
        }
       
        { show==true&&(
          <>
           <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>رقم الأرض</FormLabel>
          <Input width='400px'  onChange={(e)=>{setLandline(e.target.value)}}></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'> رقم المخطط</FormLabel>
          <Input  onChange={(e)=>{setPlan_number(e.target.value)}}  width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'> اسم المخطط </FormLabel>
          <Input  onChange={(e)=>{setPlanName(e.target.value)}}  width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>  الواجهة </FormLabel>
          <Input  onChange={(e)=>{setFacade(e.target.value)}} width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>  سعر المتر  </FormLabel>
          <InputGroup width='400px'>
          <InputLeftAddon children='ر.س' />
          <Input onChange={(e)=>{setPricePerMeter(e.target.value)}}></Input>
        </InputGroup>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600' >عرض الشارع </FormLabel>
          <Input onChange={(e)=>{setStreetWidth(e.target.value)}} width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600' >طول العقار </FormLabel>
          <Input onChange={(e)=>{setHeigt(e.target.value)}}  width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600' >عرض العقار </FormLabel>
          <Input onChange={(e)=>{setWidth(e.target.value)}} width='400px'></Input>

        </Flex>

        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600' >ارتفاع العقار </FormLabel>
          <Input onChange={(e)=>{setHigh(e.target.value)}}  width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600' >عدد الأدوار </FormLabel>
          <Input onChange={(e)=>{setFloors(e.target.value)}} width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>عدد الشقق </FormLabel>
          <Input   onChange={(e)=>{setApartments(e.target.value)}} width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600' >عدد الغرف</FormLabel>
          <Input onChange={(e)=>{setRooms(e.target.value)}} width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600' >عدد دورات المياة</FormLabel>
          <Input onChange={(e)=>{setBathrooms(e.target.value)}}  width='400px'></Input>

        </Flex>
          </>
        )

        }
       
       
       
         
        </SimpleGrid>
       
        </Card>
        <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>

<Flex px='25px' justify='space-between' mb='50px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
           اختر موقع العقار
        </Text>
       </Flex>
       <SimpleGrid columns={{sm:1, md:2}} spacing={10}>

       <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'> الاحداثية الطولية</FormLabel>
          <Input  value={latitude} onChange={(e)=>{setLatitude(e.target.value)
        handleLatitudeChange()}} width='400px' ></Input>

        </Flex>
       
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>الاحداثية العرضية</FormLabel>
          <Input  value={longitude} onChange={(e)=>{setLongitude(e.target.value)
        handleLongitudeChange()}} width='400px'></Input>

        </Flex>
       </SimpleGrid>
       <MapContainer  center={[latitude, longitude]}  zoom = '8' style={{ height: '400px', width: '100%' }}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker icon={customMarkerIcon2} position={position} draggable={true} eventHandlers={{ dragend: handleMarkerDragEnd }}>
          <Popup> (موقع العقار: {latitude} , {longitude})</Popup>
        </Marker>
      </MapContainer>
      </Card>
     

      <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>

<Flex px='25px' justify='space-between' mb='50px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
           اختر صور و  فيديوهات
        </Text>
       </Flex>
       <SimpleGrid columns={{sm:1, md:2}} spacing={10}>

       <Flex px='25px' flexDirection='column' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>  الصور</FormLabel>
           <DropzoneArea
            dropzoneText="قم برفع صور العقار"
            acceptedFiles={['image/*']}
            showAlerts={false}
            filesLimit={10}
            onChange={handleChange1}
       
        />
          
        </Flex>
       
        <Flex px='25px' flexDirection='column' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'> الفيديوهات</FormLabel>
           <DropzoneArea
            dropzoneText="قم برفع فيديوهات العقار"
            acceptedFiles={['video/*']}
            showAlerts={false}
            filesLimit={10}
            onChange={handleChange2}
       
        />
          
        </Flex>
       </SimpleGrid>
    
      </Card>
        </SimpleGrid>
        </Box>
       
   
        <Modal closeOnOverlayClick={false}  isCentered    isOpen={isOpen1} onClose={onClose1}>
        {overlay}
        <ModalContent textAlign="center">
          <ModalHeader>عملية ناجحة</ModalHeader>
          <Icon as={MdOutlineFileDownloadDone} width='50px' height='50px' color='green' margin="auto" />
          <ModalCloseButton />
          <ModalBody>
            <Text>{message}</Text>
          </ModalBody>
          <ModalFooter>
          {
              cookies.isOffice==true?<Link href='/dashboard/proposales'>
              <Button  onClick={onClose1}>اغلاق</Button></Link>:
              <Link href='/dashboard/myproposales'>
              <Button  onClick={onClose1}>اغلاق</Button></Link>
            }
            
          </ModalFooter>
        </ModalContent>
      </Modal>
      
      <Modal   isCentered    isOpen={isOpen2} onClose={onClose2}>
        {overlay}
        <ModalContent textAlign="center">
          <ModalHeader>حدث خطا </ModalHeader>
          <Icon as={BiErrorCircle} width='50px' height='50px' color='red' margin="auto" />
          <ModalCloseButton />
          <ModalBody>
            <Text>{message}</Text>
          </ModalBody>
          <ModalFooter>
            <Button  onClick={onClose2}>اغلاق</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
      
       </>)}
    </>
  )
}

