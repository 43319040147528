import React,{useState,useEffect} from 'react'

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,SimpleGrid,Flex,Text, useColorModeValue,Icon,Button  ,Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  Textarea,
  ModalCloseButton,FormControl,FormLabel,Input,
  
} from '@chakra-ui/react'
import ReactLoading from 'react-loading';
import { useHistory } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { IoIosAddCircle } from 'react-icons/io';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from "components/card/Card";
import { MdOutlineEdit } from 'react-icons/md';
import { AiFillDelete } from 'react-icons/ai';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { BiNotepad } from 'react-icons/bi';
import { GiPositionMarker } from 'react-icons/gi';
import { FaMapMarkerAlt } from 'react-icons/fa';
import L from 'leaflet';
import { BASE_URL_2 } from 'config';
export default function Map (){
  const [loading,setLoading]=useState(true)  
    const history = useHistory();
    const iconColor = useColorModeValue("brand.500", "white");
   const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const [latitude, setLatitude] = useState(24.7136);
    const [longitude, setLongitude] = useState(46.6753);
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const [cookies, setCookie, removeCookie] = useCookies(['name','id','id_office', 'email','token','isOffice','phone','phone_01','phone_2','phone_fixe','fall_number','establishment_number','address']);
    const[orders,setOrders]=useState([])
    const[proposales,setProposales]=useState([])
    const customMarkerIcon = new L.Icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
        shadowSize: [41, 41]
      });
      const customMarkerIcon2 = new L.Icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
        shadowSize: [41, 41]
      });
    useEffect(() => {
    const id_office= cookies.id_office
    axios.post(`${BASE_URL_2}/real_estates/orders/getAll`,{id_office})
      .then((response) => {
        const result = response.data
        
        setOrders(result.real_estates)
        setLoading(false)
      
      })

      axios.post(`${BASE_URL_2}/real_estates/proposals/getAll`,{id_office})
      .then((response) => {
        const result = response.data
        
        setProposales(result.real_estates)
      
      })
    }, [])
    
    return ( <> 
      {loading==true?   <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh', // Set minimum height to fill the viewport
    
      }}> <ReactLoading  type="spin" color="#422AFB" height={100} width={80} /></div>
     :( <>
     <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <SimpleGrid
      mb='20px'
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}>
         <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
        العروض
        </Text>
        <Icon as={FaMapMarkerAlt} width='40px' mr='5px' height='40px' color='red' />
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
        الطلبات
        </Text>
        <Icon as={FaMapMarkerAlt} width='40px' mr='5px' height='40px' color='green' />
      </Flex>
      </Card>
      <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>


<MapContainer  center={[latitude, longitude]}  zoom = '8' style={{ height: '700px', width: '100%' }}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {orders.map((item,index)=>{
            return(
                <> 
         <Marker  icon={customMarkerIcon} position={[item.latitude,item.longitude]} draggable={false}>
          <Popup>


          <Flex px='25px' justify='space-between'  align='center'>
        <Text
          className='p_leaflet'
          color={textColor} 
          lineHeight='100%'
          paddingLeft='20px' 
          marginBottom='5px'
        >
        اسم صاحب الط
        </Text>
        <Text
          color={textColor}
          className='p_leaflet'
          fontWeight='700'
          lineHeight='100%' 
        >
        {item.user_name}
        </Text>
            </Flex>
            <Flex px='25px' justify='space-between'  align='center'>
        <Text
          color={textColor} 
          className='p_leaflet'
          lineHeight='100%'
          paddingLeft='20px' 
        >
        نوع العقار
        </Text>
        <Text
          color={textColor}
          className='p_leaflet'
          fontWeight='700'
          lineHeight='100%' 
        >
        {item.type}
        </Text>
            </Flex>
            <Flex px='25px' justify='space-between'  align='center'>
        <Text
          color={textColor} 
          className='p_leaflet'
          lineHeight='100%'
          paddingLeft='20px' 
        >
     المدينة
        </Text>
        <Text
          color={textColor}
          className='p_leaflet'
          fontWeight='700'
          lineHeight='100%' 
        >
        {item.town}
        </Text>
            </Flex>
            <Flex px='25px' justify='space-between'  align='center'>
        <Text
          color={textColor} 
          className='p_leaflet'
          lineHeight='100%'
          paddingLeft='20px' 
        >
      الحي
        </Text>
        <Text
          color={textColor}
          className='p_leaflet'
          fontWeight='700'
          lineHeight='100%' 
        >
        {item.city}
        </Text>
            </Flex>
            <Flex px='25px' justify='space-between'  align='center'>
        <Text
          color={textColor} 
          className='p_leaflet'
          lineHeight='100%'
          paddingLeft='20px' 
        >
        مساحة العقار
        </Text>
        <Text
          color={textColor}
          className='p_leaflet'
          fontWeight='700'
          lineHeight='100%' 
        >
        {item.surface}(متر²)
        </Text>
            </Flex>
            <Flex px='25px' justify='space-between'  align='center'>
        <Text
          color={textColor} 
          className='p_leaflet'
          lineHeight='100%'
          paddingLeft='20px' 
        >
        سعر العقار
        </Text>
        <Text
          color={textColor}
          className='p_leaflet'
          fontWeight='700'
          lineHeight='100%' 
        >
        {item.price}(ر.س)
        </Text>
            </Flex>
            <Flex px='25px' justify='center'  align='center'>
            <Button  
        margin='auto'
        fontSize='12px'
        borderRadius='10px'   
        backgroundColor={bgButton}       
        color={iconColor} 
        onClick={()=>{
            setCookie('id_real_estate',item.id_real_estate, { path: '/', expires: new Date(Date.now() + 86400000) });
            history.push('/dashboard/order/details');
        }}
        > المزيد </Button>
        </Flex>
          </Popup>
        </Marker>
                </>
            )
       

        })}

        {proposales.map((item,index)=>{
            return(
                <> 
         <Marker  position={[item.latitude,item.longitude]} icon={customMarkerIcon2} draggable={false}>
          <Popup> 
          <Flex px='25px' justify='space-between'  align='center'>
        <Text
          className='p_leaflet'
          color={textColor} 
          lineHeight='100%'
          paddingLeft='20px' 
          marginBottom='5px'
        >
        اسم صاحب العرض
        </Text>
        <Text
          color={textColor}
          className='p_leaflet'
          fontWeight='700'
          lineHeight='100%' 
        >
        {item.user_name}
        </Text>
            </Flex>
            <Flex px='25px' justify='space-between'  align='center'>
        <Text
          color={textColor} 
          className='p_leaflet'
          lineHeight='100%'
          paddingLeft='20px' 
        >
        نوع العقار
        </Text>
        <Text
          color={textColor}
          className='p_leaflet'
          fontWeight='700'
          lineHeight='100%' 
        >
        {item.type}
        </Text>
            </Flex>
            <Flex px='25px' justify='space-between'  align='center'>
        <Text
          color={textColor} 
          className='p_leaflet'
          lineHeight='100%'
          paddingLeft='20px' 
        >
     المدينة
        </Text>
        <Text
          color={textColor}
          className='p_leaflet'
          fontWeight='700'
          lineHeight='100%' 
        >
        {item.town}
        </Text>
            </Flex>
            <Flex px='25px' justify='space-between'  align='center'>
        <Text
          color={textColor} 
          className='p_leaflet'
          lineHeight='100%'
          paddingLeft='20px' 
        >
      الحي
        </Text>
        <Text
          color={textColor}
          className='p_leaflet'
          fontWeight='700'
          lineHeight='100%' 
        >
        {item.city}
        </Text>
            </Flex>
            <Flex px='25px' justify='space-between'  align='center'>
        <Text
          color={textColor} 
          className='p_leaflet'
          lineHeight='100%'
          paddingLeft='20px' 
        >
        مساحة العقار
        </Text>
        <Text
          color={textColor}
          className='p_leaflet'
          fontWeight='700'
          lineHeight='100%' 
        >
        {item.surface}(متر²)
        </Text>
            </Flex>
            <Flex px='25px' justify='space-between'  align='center'>
        <Text
          color={textColor} 
          className='p_leaflet'
          lineHeight='100%'
          paddingLeft='20px' 
        >
        سعر العقار
        </Text>
        <Text
          color={textColor}
          className='p_leaflet'
          fontWeight='700'
          lineHeight='100%' 
        >
        {item.price}(ر.س)
        </Text>
            </Flex>
            <Flex px='25px' justify='center'  align='center'>
        <Button  
        margin='auto'
        fontSize='12px'
        borderRadius='10px'   
        backgroundColor={bgButton}       
        color={iconColor} 
        onClick={()=>{
            setCookie('id_real_estate',item.id_real_estate, { path: '/', expires: new Date(Date.now() + 86400000) });
            history.push('/dashboard/proposal/details');
        }}
        > المزيد </Button>
        </Flex>
          </Popup>
        </Marker>
                </>
            )
       

        })}
        
      </MapContainer>
      </Card>
      </SimpleGrid>
      </Box>
    </>)}</>
  )
}

