/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import { useCookies } from 'react-cookie';
import { BASE_URL_1 } from "config";
import SignInCentered from "views/auth/signIn";
export function SidebarLinks(props) {
  const [cookies, setCookie, removeCookie] = useCookies(['name','token','pricing', 'email','token','isOffice','phone','phone_01','phone_2','phone_fixe','fall_number','establishment_number','address']);

  const isOffice = cookies.isOffice;


  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
   if(cookies.isOffice==true){
    
    if ((window.location.href ===`${BASE_URL_1}/dashboard/order/update` || window.location.href ===`${BASE_URL_1}/dashboard/order/details` || window.location.href ===`${BASE_URL_1}/dashboard/order/add`  )&& routeName==="/orders") {
      return true;
    }

    if ((window.location.href ===`${BASE_URL_1}/dashboard/proposal/details`|| window.location.href ===`${BASE_URL_1}/dashboard/proposal/update` || window.location.href ===`${BASE_URL_1}/dashboard/proposals/add`  )&& routeName==="/proposales") {
      return true;
    }

   }
  else{
    if ((window.location.href ===`${BASE_URL_1}/dashboard/order/update` || window.location.href ===`${BASE_URL_1}/dashboard/order/details` || window.location.href ===`${BASE_URL_1}/dashboard/order/add`  )&& routeName==="/myorders") {
      return true;
    }

    if ((window.location.href ===`${BASE_URL_1}/dashboard/proposal/details` || window.location.href ===`${BASE_URL_1}/dashboard/proposal/update` || window.location.href ===`${BASE_URL_1}/dashboard/proposals/add`  )&& routeName==="/myproposales") {
      return true;
    }

  }

    return location.pathname.includes(routeName);
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {

    return routes.map((route, index) => {
      if (route.category  ) {
       
        return (
          <>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight='bold'
              mx='auto'
              ps={{
                sm: "5px",
                xl: "16px",
              }}
              pt='10px'
              pb='12px'
              key={index}>
              {route.name}
            </Text>
            {createLinks(route.items)}
          </>
        );
      }  
      else if (
        (route.layout === "/admin"  && route.name!='Sign In') ||
       
        (route.layout === "/dashboard" && route.name!='Sign In' && route.name!="اضافة عرض" && route.name!=" تفاصيل العرض" && route.name!=" تعديل العرض"&& route.name!="تعديل الطلب"&&route.name!="اضافة طلب"&&route.name!="تفاصيل الطلب"&&route.name!='الحساب'&&route.name!="تفاصيل الطلب" && route.isOffice==true && isOffice==true) ||(route.layout === "/dashboard" && route.isOffice==false&&isOffice==false)||(route.layout === "/dashboard" && route.pricing==true&&cookies.pricing!="مجانية") || (route.layout === "/dashboard" &&  route.name!='Sign In'  && route.name!="اضافة عرض" && route.name!=" تعديل العرض"&& route.name!="تعديل الطلب"&& route.name!="تفاصيل العرض" && route.name!="اضافة طلب"&&route.name!="تفاصيل الطلب"&&route.name!='الحساب'&& route.all==true  ) )
       { 
        return (
          <NavLink key={index} to={route.layout + route.path} exact={route.exact}>
            {route.icon ? (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Flex w='100%' alignItems='center' justifyContent='center'>
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me='18px'>
                      {route.icon}
                    </Box>
                    <Text
                      me='auto'
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }>
                      {route.name}
                    </Text>
                  </Flex>
                  <Box
                    h='36px'
                    w='4px'
                    bg={
                      activeRoute(route.path.toLowerCase())
                        ? brandColor
                        : "transparent"
                    }
                    borderRadius='5px'
                  />
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  py='5px'
                  ps='10px'>
                  <Text
                    me='auto'
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }>
                    {route.name}
                  </Text>
                  <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                </HStack>
                
              </Box>
            )}
          </NavLink>
        );
      }
    });
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
