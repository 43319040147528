import React,{useState,useEffect} from 'react'

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,SimpleGrid,Flex,Text, useColorModeValue,Icon,Button  ,Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  Textarea,
  ModalCloseButton,FormControl,FormLabel,Input,
} from '@chakra-ui/react'
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react'
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { IoIosAddCircle } from 'react-icons/io';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from "components/card/Card";
import { MdOutlineEdit } from 'react-icons/md';
import { AiFillDelete } from 'react-icons/ai';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { BiNotepad } from 'react-icons/bi';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { BASE_URL_2 } from 'config';
import ReactLoading from 'react-loading';
export default function Users  ()  {
const [cookies] = useCookies(['name','id','pricing', 'email','token','isOffice','phone','phone_01','phone_2','phone_fixe','fall_number','establishment_number','address']);
function isValid(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  console.log(emailRegex.test(email))
  return emailRegex.test(email);
}

  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
  )
 const [users,setUsers]=useState([])
 const [town,setCityTown]=useState('')
 const [options,setOptions]=useState([])
 const [maxp,setMaxP]=useState('')
 const [maxo,setMaxO]=useState('')

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const notify = (text) => toast.success( text,{position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored", 
  fontFamilly:"Cairo"
});
const notify2 = (text) => toast.error( text,{position: "bottom-right",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
theme: "colored", 
fontFamilly:"Cairo"
});
const [loading,setLoading]=useState(true)

  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure(); // First modal
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure(); // Second modal
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure(); // Second modal
  const [overlay, setOverlay] = useState(<OverlayOne />)
  const iconColor = useColorModeValue("brand.500", "white");
  useEffect(() => {
    const id_office= cookies.id
    axios.post(`${BASE_URL_2}/users/user/getAllByOffice`,{id_office})
      .then((response) => {
        const result = response.data
       
        setUsers(result.users)
      
      })

      axios.post(`${BASE_URL_2}/real_estates/office/shared`,{id_office})
        .then((response) => {
          const result = response.data
         
          setMaxO(result.office.nb_sharabel_order)
          setMaxP(result.office.nb_sharabel_proposal)
          setLoading(false)
        
        })
    
  }, [])
  const [name,setName]= useState('')
  const [text,setText]= useState('')
  const [email,setEmail]= useState('')
  const [address,setAddress]= useState('')
  const [phone,setPhone]= useState('')
  const [password,setPassword]= useState('')
 const [type,setType]=useState('')
 const [fall_number,setFallNumber]=useState('')
  const [id_user,setIdUser]= useState('')
  const [notes,setNotes]=useState([])
  const [shared,setShared]=useState(false)
  const[nb_order,setNbOffer]=useState(1)
  const[nb_proposal,setNbProposal]=useState(1)

  const getNotes = async()=>{
    axios.post(`${BASE_URL_2}/users/note/getAllByUser`,{id_user})
    .then((response) => {
      const result = response.data
     
      setNotes(result.notes)
    
    })
  }
  const add = async()=>{
    const pricing=cookies.pricing

    const id_office= cookies.id
  axios.post(`${BASE_URL_2}/users/user/signup`,{name,email,password,phone,address,id_office,type,shared,nb_proposal,nb_order,pricing,fall_number})
  .then((response) => {
    const result = response.data
      if(!result.message){

        axios.post(`${BASE_URL_2}/users/user/getAllByOffice`,{id_office})
      .then((response) => {
        const result = response.data
       
        setUsers(result.users)
        onClose1()
        notify("تم اضافة  مستخدم  جديد بنجاح")
      
      })
  
    }
    else{
      onClose1()
      notify2(result.message)
    }
  
  })

   
}
const delet = async (email)=>{
    const id_office= cookies.id
  axios.post(`${BASE_URL_2}/users/user/delete`,{email,id_office})
  .then((response) => {
    const result = response.data
    if(result.succes){
        axios.post(`${BASE_URL_2}/users/user/getAllByOffice`,{id_office})
      .then((response) => {
        const result = response.data
       
        setUsers(result.users)
        onClose1()
        notify("تم حذف المستخدم  بنجاح")
      
      })
  
    }
    else{
      onClose1()
      notify2(result.message)
    }
  
  })


}
 const addNote =async()=>{
  const id_office= cookies.id
  axios.post(`${BASE_URL_2}/users/note/add`,{id_office,id_user,text})
  .then((response) => {
    const result = response.data
    if(result.succues){

        axios.post(`${BASE_URL_2}/users/user/getAllByOffice`,{id_office})
      .then((response) => {
        const result = response.data
       
        setUsers(result.users)
        onClose3()
        notify("تم اضافة الملاحظة بنجاح")
      
      })
  
    }
    else{
      onClose1()
      notify2(result.message)
    }
  
  })
 }

const update = async ()=>{
    const id_office= cookies.id

  axios.post(`${BASE_URL_2}/users/user/office/update`,{name,phone,address,email,type,fall_number})
  .then((response) => {
    const result = response.data
    if(result.succes){

        axios.post(`${BASE_URL_2}/users/user/getAllByOffice`,{id_office})
      .then((response) => {
        const result = response.data
       
        setUsers(result.users)
        onClose2()
        notify("تم تعديل معلومات المستخدم  بنجاح")
      
      })
  
    }
    else{
      onClose2()
      notify2(result.message)
    }
  
  })


}
  
  
  return ( <> 
      {loading==true?   <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh', // Set minimum height to fill the viewport
   
      }}> <ReactLoading  type="spin" color="#422AFB" height={100} width={80} /></div>
     :(
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <SimpleGrid
      mb='20px'
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}>
         <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={ "scroll"}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
          قائمة المستخدمين
        </Text>
       <Button  
       backgroundColor={bgButton}  
        borderRadius='10px'   
        color={iconColor}  
        onClick={()=>{
        setOverlay(<OverlayOne />)
        onOpen1()
        }}
      
        >اضافة مستخدم <Icon as={IoIosAddCircle} width='20px' mr='5px' height='20px' color={iconColor} /></Button>
      </Flex>
      <Table  variant='simple' color='gray.500' mb='24px'>
      <TableContainer>
    <Table variant='simple'>
    <Thead>
        
            <Tr >
             
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                  الاسم
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                  الصفة
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                  البريد الالكتروني
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                   رقم الهاتف
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                   رقم رخصة فال
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                   العنوان
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                   الملاحظات
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "20px" }}
                    color='gray.400'>
                    
                  </Flex>
                  العمليات
                </Th>
            
            </Tr>
         
        </Thead>
      <Tbody>
        {users.map((item,index)=>{
          return(
            <Tr>
            <Td>{item.name}</Td>
            <Td>{item.type}</Td>
            <Td>{item.email}</Td>
            <Td>{item.phone}</Td>
            <Td>{item.fall_number}</Td>
            <Td>{item.address}</Td>
            <Td > 
            <Icon as={BiNotepad} width='20px' mr='10px' height='20px'  cursor='pointer' color={iconColor} onClick={()=>
              {setIdUser(item.id_user)
               getNotes()
              onOpen3()}}
             /></Td>
            <Td ><Icon as={MdOutlineEdit} width='20px' mr='10px' height='20px' cursor='pointer' color={iconColor}  onClick={()=>{
              setName(item.name)
              setEmail(item.email)
              setPhone(item.phone)
              setIdUser(item.id_user)
              setFallNumber(item.fall_number)
             setAddress(item.address)
              onOpen2()
            }}  /> 
            
            <Icon as={AiFillDelete} width='20px' mr='10px' height='20px'  cursor='pointer' color={iconColor}  onClick={()=>delet(item.email)}/></Td>
          </Tr>
          )
        })}  
      </Tbody>
    </Table>
  </TableContainer>
      </Table>
    </Card>
          
    </SimpleGrid>
    <Modal isCentered    isOpen={isOpen1} onClose={onClose1}>
        {overlay}
        <ModalContent textAlign="center">
          <ModalHeader> ادخل معلومات المستخدم </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
           <FormControl  display='flex' flexDirection='row' justifyContent='space-between' mb='5px'>
              <FormLabel> الاسم</FormLabel>
              <Input  width="200px"   onChange={(event)=>{setName(event.target.value)}}/>
            </FormControl>

            <FormControl display='flex' flexDirection='row' justifyContent='space-between' mb='5px'>
              <FormLabel> الصفة</FormLabel>
              <Select width="200px"   placeholder='اختر الصفة' onChange={(event)=>{
                setType(event.target.value)}}>
              
              
                  <option value='مشتري' >مشتري</option>
                  <option value='صاحب عقارات' >صاحب عقارات</option>
                  <option value=' مسوق' > مسوق</option>
                  
          
             </Select>
            </FormControl>
            <FormControl  display='flex' flexDirection='row' justifyContent='space-between' mb='5px'>
              <FormLabel> البريد الالكتروني</FormLabel>
              <Input   width="200px"   onChange={(event)=>{setEmail(event.target.value)}}/>
            </FormControl>
            <FormControl  display='flex' flexDirection='row' justifyContent='space-between' mb='5px'>
              <FormLabel >كلمة المرور</FormLabel>
              <Input width="200px"   type="password"   onChange={(event)=>{setPassword(event.target.value)}}/>
            </FormControl>
            <FormControl  display='flex' flexDirection='row' justifyContent='space-between' mb='5px'>
              <FormLabel> رقم الهاتف</FormLabel>
              {/* <Input    onChange={(event)=>{setPhone(event.target.value)}}/> */}
              <PhoneInput
        
              style={{width:"200px"}}
              defaultCountry="sa"
              textAlign="left"
             onChange={(phone) => setPhone(phone)}
          />
            </FormControl>
            <FormControl  display='flex' flexDirection='row' justifyContent='space-between' mb='5px'>
              <FormLabel>رقم رخصة فال</FormLabel>
              <Input   width="200px"   onChange={(event)=>{setFallNumber(event.target.value)}}/>
            </FormControl>
            <FormControl  display='flex' flexDirection='row' justifyContent='space-between' mb='5px'>
              <FormLabel>العنوان</FormLabel>
              <Input   width="200px"   onChange={(event)=>{setAddress(event.target.value)}}/>
            </FormControl>
            <FormControl  display='flex' flexDirection='row' justifyContent='space-between' mb='5px'>
              <FormLabel   >تحديد عدد العروض و الطلبات</FormLabel>
             
              <Checkbox  value="true" onChange={(e)=>{setShared(e.target.checked) 
             }}></Checkbox>
            </FormControl>
            {shared==true && <>
            <FormControl  display='flex' flexDirection='row' justifyContent='space-between' mb='5px'>
              <FormLabel    >عدد العروض</FormLabel>
              <NumberInput defaultValue={1} min={1}  type='number' max={maxp}  width="200px"   onChange={(valueString)=>{setNbProposal(valueString)}}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
            </FormControl>
            <FormControl  display='flex' flexDirection='row' justifyContent='space-between' mb='5px'>
              <FormLabel    >عدد الطلبات</FormLabel>
              <NumberInput defaultValue={1} min={1}  type='number' max={maxo}  width="200px"   onChange={(valueString)=>{setNbOffer(valueString)
            }}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
            </FormControl></>}
           
            

           
          </ModalBody>

          
         
      
          <ModalFooter>
          <Button disabled={name&&fall_number&&email&&isValid(email)&&phone&&password&&address&&type ?false:true} colorScheme='blue' mr={3}  onClick={add}>
              اضافة
            </Button>
            <Button  onClick={onClose1}>اغلاق</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isCentered    isOpen={isOpen2} onClose={onClose2}>
        {overlay}
        <ModalContent textAlign="center">
          <ModalHeader> تعديل معلومات المستخدم </ModalHeader>
         
          <ModalCloseButton />
          <ModalBody>
          
          <FormControl  display='flex' flexDirection='row' justifyContent='space-between' mb='5px'>
              <FormLabel> الاسم</FormLabel>
              <Input     width="200px"  value={name}  onChange={(event)=>{setName(event.target.value)}}/>
            </FormControl>
            <FormControl  display='flex' flexDirection='row' justifyContent='space-between' mb='5px'>
            <FormLabel> الصفة</FormLabel> 
            <Select   width="200px"   value={type} placeholder='اختر الصفة' onChange={(event)=>{
                setType(event.target.value)}}>
              
              
                  <option value='مشتري' >مشتري</option>
                  <option value='مسوق' >مسوق</option>
                 
                  <option value='صاحب عقارات' >صاحب عقارات</option>
                  
            
                    
                    
             </Select>
            </FormControl >
            <FormControl  display='flex' flexDirection='row' justifyContent='space-between' mb='5px'>
           
              <FormLabel> رقم الهاتف</FormLabel>
              
              <PhoneInput
              defaultCountry="sa"
              value={phone}
              textAlign="left"
             onChange={(phone) => setPhone(phone)}
          />
            </FormControl>
            <FormControl  display='flex' flexDirection='row' justifyContent='space-between' mb='5px'>
              <FormLabel    >العنوان</FormLabel>
              <Input    width="200px"   value={address}  onChange={(event)=>{setAddress(event.target.value)}}/>
            </FormControl>
            <FormControl  display='flex' flexDirection='row' justifyContent='space-between' mb='5px'>
              <FormLabel    >   رقم رخصة فال</FormLabel>
              <Input    width="200px"   value={fall_number}  onChange={(event)=>{setFallNumber(event.target.value)}}/>
            </FormControl>
       
       
        
          
          </ModalBody>

          
         
      
          <ModalFooter>
          <Button  disabled={name&&fall_number&&phone&&address&&type ?false:true}  colorScheme='blue' mr={3}  onClick={update}>
              تعديل
            </Button>
            <Button  onClick={onClose2}>اغلاق</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isCentered    isOpen={isOpen3} onClose={onClose3}>
        {overlay}
        <ModalContent textAlign="center">
          <ModalHeader>  الملاحظات </ModalHeader>
         
          <ModalCloseButton />
          <ModalBody>
            {notes.length!=0 &&  
             (<div>
              
           
         <FormLabel> قائمة الملاحظات </FormLabel>
          <div>
          {notes.map((item,index)=>{
            return(
              <Text textAlign='right'>  <span style={{fontWeight:"600"}}> ملاحظة {index +1}</span> {item.text}</Text>
            )
          })}</div></div>)}
        
          <FormControl>
              <FormLabel mt='15px'> ملاحضة جديدة</FormLabel>
              <Textarea     onChange={(event)=>{setText(event.target.value)}}/>
            </FormControl>
            
          
          </ModalBody>

          
         
      
          <ModalFooter>
          <Button  disabled={text?false:true}  colorScheme='blue' mr={3}  onClick={addNote}>
              اضافة
            </Button>
            <Button  onClick={onClose3}>اغلاق</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ToastContainer  position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"/>
  </Box>)}

  </>)
}



