import React, { useEffect,useState } from 'react';
import '../landing/css/bootstrap.min.css'; // Import your CSS file
import '../landing/lib/owlcarousel/assets/owl.carousel.min.css'; // Import your JavaScript file
import '../landing/lib/animate/animate.min.css'
import "../landing/css/style.css"
import WOW from 'wow.js';
import 'animate.css/animate.min.css';
import $ from 'jquery'; // Import jQuery
import header from "./img/header.png"
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import slide1 from "./img/slide1.jpg"
import slide2 from "./img/slide2.jpg"
import slide3 from "./img/slide3.jpg"
import slide4 from "./img/slide4.jpg"
import slide5 from "./img/slide5.jpg"
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { BASE_URL_2 } from 'config';
import ReactLoading from 'react-loading';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import axios from 'axios';
export default function Landing() {
    const [loading,setLoading]=useState(true)
    const [pricing,setPricing]=useState([])
    const [cookies, setCookie, removeCookie] = useCookies(['pricing']);
    const [email,setEmail]=useState('')
    const [subject,setSubject]=useState('')
    const [text,setText]=useState('')
    const [name,setName]=useState('')
    const add = async(e)=>{
        e.preventDefault()
        axios.post(`${BASE_URL_2}/users/message/add`,{email,subject,text,name})
        .then((response) => {
          const result = response.data
          console.log(result)
          window.location.reload();
         })
        
    }
    const history = useHistory();
    useEffect(() => {
        (function ($) {
            "use strict";

            var spinner = function () {
                setTimeout(function () {
                    if ($('#spinner').length > 0) {
                        $('#spinner').removeClass('show');
                    }
                }, 1);
            };
            spinner();
            
            
            // Initiate the wowjs
            new WOW().init();
        
        
            // Sticky Navbar
            $(window).scroll(function () {
                if ($(this).scrollTop() > 45) {
                    $('.navbar').addClass('sticky-top shadow-sm');
                } else {
                    $('.navbar').removeClass('sticky-top shadow-sm');
                }
            });
        
        
            // Smooth scrolling on the navbar links
            $(".navbar-nav a").on('click', function (event) {
                if (this.hash !== "") {
                    event.preventDefault();
                    
                    $('html, body').animate({
                        scrollTop: $(this.hash).offset().top - 45
                    }, 1500);
                    
                    if ($(this).parents('.navbar-nav').length) {
                        $('.navbar-nav .active').removeClass('active');
                        $(this).closest('a').addClass('active');
                    }
                }
            });
            
            
            // Back to top button
            $(window).scroll(function () {
                if ($(this).scrollTop() > 100) {
                    $('.back-to-top').fadeIn('slow');
                } else {
                    $('.back-to-top').fadeOut('slow');
                }
            });
            $('.back-to-top').click(function () {
                $('html, body').animate({scrollTop: 0}, 1500,);
                return false;
            });
        
        
          
        
            
        })($);


        axios.post(`${BASE_URL_2}/users/pricing/getAll`,null,{
            headers: {
              'Authorization': `${cookies.token}`
            }})
          .then((response) => {
            const result = response.data
           
            setPricing(result.pricing)
            setLoading(false)
          })
        
        
    }, [])


    
    return ( <> 
        {loading==true?   <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh', // Set minimum height to fill the viewport
     
        }}> <ReactLoading  type="spin" color="#422AFB" height={100} width={80} /></div>
       :(
        <div classNameName="App">
          
    <div className=" bg-white p-0">
        <div className=" position-relative p-0" id="home">
            <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
                <a href="" className="navbar-brand p-0">
                    <h1 style={{fontSize:"30px"}} className="m-0">Maktabi</h1>
                  {/* <img src="img/logo.png" alt="Logo"> */}
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="fa fa-bars"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav mx-auto py-0">
                        <a href="#home" className="nav-item nav-link active">الرئيسية</a>
                        <a href="#about" className="nav-item nav-link"> حول المنصة</a>
                        <a href="#feature" className="nav-item nav-link">خدماتنا</a>
                        <a href="#pricing" className="nav-item nav-link">الباقات</a>
                        <a href="#contact" className="nav-item nav-link">تواصل معنا</a>
                    </div>
                    
                </div>
            </nav>

            <div className=" bg-primary hero-header">
                <div className=" px-lg-5">
                    <div className="row g-5">
                        <div className= "container col-lg-7 text-center text-lg-start" style={{marginTop:"200px"}} >
                        <h1 style={{fontSize:"30px"}} className="text-white mb-4 animated slideInDown">!شريكك المثالي للتسيير العقاري  -Maktabi مرحبًا بك في</h1>

                            <p className="text-white pb-3 animated slideInDown">انضم إلينا اليوم واستمتع برحلة عقارية لا تنسى</p>
 
                            <a  href="#pricing" style={{marginRight:"50px"}} className="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5  rounded-pill animated slideInRight start-btn" >ابدا الان </a>
                            <a   href="/maktabi/sign-in" className="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5  rounded-pill animated slideInRight start-btn" > تسجيل الدخول </a>
                        </div>
                        <div className="col-lg-4 d-flex justify-content-center justify-content-lg-end wow fadeInUp"   data-wow-delay="0.3s">
                                <img  style={{marginRight:"50px"}}   className="img-fluid" src={header} alt=""/>
                               
                            
                        </div>
                    </div>
                 
                </div>
            </div>
   
        </div>
    
        <div className="container-xxl py-5" id="about">
            <div className="container py-5 px-lg-5">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className="text-primary-gradient fw-medium"> حول المنصة</h5>
                    <h1 className="mb-5">الشريك العقاري الامثل </h1>
                </div>
                <div className="row g-4">
                <div className="col-xl-8 wow fadeInUp" data-wow-delay="0.1s">
                        <p className="mb-4"> منصة "مكتبي" هي وجهتك المثلى في عالم التسويق العقاري وإدارة العقارات. تقدم لأصحاب المكاتب العقارية ووسطاء العقارات تجربة فريدة وشاملة لتحقيق النجاح في صناعة العقارات. تعتمد "مكتبي" على تكنولوجيا متطورة وواجهة مستخدم فعّالة تمكّن الوكلاء العقاريين من إدارة ممتلكاتهم بكل يسر وسهولة. يمكن لأصحاب المكاتب العقارية البحث وإضافة الممتلكات بسهولة، مع إمكانية عرض التفاصيل الدقيقة والصور المميزة لتجذب انتباه العملاء المحتملين</p>
                
                    </div>
                    <div className="col-xl-4 d-flex justify-content-center justify-content-lg-end wow fadeInUp" data-wow-delay="0.3s">
                    <Swiper
                     autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                            effect={'cards'}
                            grabCursor={true}
                            modules={[EffectCards,Autoplay]}
                            className="mySwiper"
                        >
                            <SwiperSlide> <img style={{height:"100%"}} src={slide1} alt=""/> </SwiperSlide>
                            <SwiperSlide> <img src={slide2} alt=""/></SwiperSlide>
                            <SwiperSlide><img src={slide3} alt=""/></SwiperSlide>
                            <SwiperSlide><img src={slide4} alt=""/></SwiperSlide>
                            <SwiperSlide><img src={slide5} alt=""/></SwiperSlide>
                           
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>

     
        <div className="container-xxl py-5" id="feature">
            <div className="container py-5 px-lg-5">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className="text-primary-gradient fw-medium">خدماتنا</h5>
                    <h1 className="mb-5">خائص و مزايا فريدة</h1>
                </div>
                <div className="row g-4">
                  
                    <div  style={{textAlign:"center"}} className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="feature-item bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4"  style={{width:"60px",height:"60px"}}>
                                <i className="fa fa-building  text-white fs-4"></i>
                            </div>
                            <h5 className="mb-3">إدارة العروض والطلبات</h5>
                            <p className="m-0">نحن نقدم خدمات إدارة العروض والطلبات التي تسهل على أصحاب المكاتب العقارية ووسطاء العقارات تنظيم عروضهم وطلباتهم بشكل فعال ومنظم. يمكن للمستخدمين إضافة وإدارة عروضهم وطلباتهم بسهولة </p>
                            </div>
                    </div>
                    <div style={{textAlign:"center"}} className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="feature-item bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{width:"60px",height:"60px"}}>
                                <i className="fa fa-user fa-user text-white fs-4"></i>
                            </div>
                            <h5 className="mb-3">ادارة المستخدمين </h5>
                            <p className="m-0">نحن نوفر نظام إدارة المستخدمين المتقدم الذي يسمح لك بإنشاء حسابات للمستخدمين وتعيين صلاحيات مخصصة. يمكنك متابعة نشاطات المستخدمين وتحليل تفاعلهم مع العروض والطلبات.</p>
                        </div>
                    </div>
                    <div style={{textAlign:"center"}} className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="feature-item bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4"  style={{width:"60px",height:"60px"}}>
                                <i className="fa fa-check text-white fs-4"></i>
                               
                            </div>
                            <h5 className="mb-3">البحث عن أفضل العقارات</h5>
                            <p className="m-0">حن نقدم لك واجهة بحث متقدمة تساعدك على البحث عن العقارات بسرعة وفعالية وفقًا لمعاييرك واحتياجاتك. سواء كنت تبحث عن شقة للإيجار أو فيلا للبيع، يمكنك العثور على الخيارات المناسبة بسهولة</p>
                        </div>
                    </div>
                    <div style={{textAlign:"center"}} className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="feature-item bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{width:"60px",height:"60px"}}>
                                <i className="fa fa-map text-white fs-4"></i>
                            </div>
                            <h5 className="mb-3"> خرائط العقارات </h5>
                            <p className="m-0">استمتع بخاصية خرائط العقارات المتميزة التي نوفرها .يمكنك تحديد المناطق واستعراض  العقارات في المناطق المحددة، مما يسهل على العملاء العثور على العقار المثالي. </p>
                        </div>
                    </div>
                    <div style={{textAlign:"center"}} className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="feature-item bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4" style={{width:"60px",height:"60px"}}>
                                <i className="fa fa-share text-white fs-4"></i>
                            </div>
                            <h5 className="mb-3"> المشاركة عبر  مواقع التواصل الاجتماعي</h5>
                            <p className="m-0">.يمكن للعملاء التفاعل مع العروض والطلبات ومشاركتها بسهولة على منصات مثل فيسبوك، تويتر، وإنستغرام، مما يساعد في زيادة نطاق الوصول وجذب المزيد من العملاء </p>
                        </div>
                     </div>
                     <div  style={{textAlign:"center"}} className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="feature-item bg-light rounded p-4">
                            <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4"  style={{width:"60px",height:"60px"}}>
                                <i className="fa fa-file text-white fs-4"></i>
                            </div>
                            <h5 className="mb-3"> تقارير وإحصائيات متقدمة </h5>
                            <p className="m-0">حرص على تزويدك بتقارير دورية وإحصائيات دقيقة تمكنك من مراقبة وفهم أداء عقاراتك بشكل شامل. تُعد هذه الميزة حجر الزاوية لاتخاذ قرارات مستنيرة وتحسين استراتيجيات التسويق الخاصة بك</p>
                        </div>
                 </div>
                        
                   
                </div>
            </div>
        </div>
     

        <div className="container-xxl py-5" id="pricing">
            <div className="container py-5 px-lg-5">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className="text-primary-gradient fw-medium">باقات الاشتراك </h5>
                    <h1 className="mb-5">اختر باقة تناسب احتياجاتك</h1>
                </div>
                <div className="tab-className text-center pricing wow fadeInUp" data-wow-delay="0.1s">
                  
                    <div className="tab-content text-start">
                        <div id="tab-1" className="tab-pane fade show p-0 active">
                            <div className="row g-4">
                               
                            
                            
                                {pricing.map((item,index)=>{
                            return(
                                <div  className="col-lg-3">
                                <div  className="bg-light rounded">
                                    <div style={{textAlign:"center"}} className="border-bottom p-4 mb-4">
                                        <h4 className="text-primary-gradient mb-1">{item.name!="مجانية"?item.name:"باقة تجريبية 14 يوم "}</h4>
                                       
                                    </div>
                                    <div className="p-4 pt-0">
                                        <h1  style={{textAlign:"center"}} className="mb-3">
                                            <small className="align-top"   style={{fontSize:"22px",lineHeight:"45px"}} >رس</small>{item.price}<small
                                                className="align-bottom"  style={{fontSize:"16px",lineHeight:"40px"}} >/ شهر</small>
                                        </h1>
                                        <div  style={{textAlign:"right",justifyContent:'end'}} className="d-flex  mb-3"><span>
  {item.name !== 'مجانية' ? `مكتب واحد + يصل الى ${item.nb_user} مستخدم` : `يصل الى ${item.nb_user} مستخدم`}
</span>
<i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                        <div  style={{textAlign:"right",justifyContent:'end'}} className="d-flex  mb-3"><span>يصل الى {item.nb_proposal}  عروض </span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                        <div  style={{textAlign:"right",justifyContent:'end'}}className="d-flex  mb-3"><span>يصل الى {item.nb_order} طليات</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                        <div style={{textAlign:"right",justifyContent:'end'}} className="d-flex  mb-2"><span>خدمة الخرائط</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                        <div style={{textAlign:"right",justifyContent:'end'}} className="d-flex  mb-2"><span> صور + فيدوهات</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                        <div style={{textAlign:"center"}} >  <a  onClick={()=>{
                            setCookie('pricing', item.name, { path: '/', expires: new Date(Date.now() + 86400000) });
                            history.push('/maktabi/sign-up')
                        }} className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">{item.name!='مجانية'?"اشترك الان":"طلب عرض تجريبي "} </a></div>
                                      
                                    </div>
                                </div>
                                
                            </div>
                            )


                                })}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
     

      
       
        <div className="container-xxl py-5" id="contact">
            <div className="container py-5 px-lg-5">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <h5 className="text-primary-gradient fw-medium">تواصل معنا </h5>
                    <h1 className="mb-5">ارسل استفساراتك و اسئلتك</h1>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <div className="wow fadeInUp" data-wow-delay="0.3s">
                            <form>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input onChange={(e)=>{setName(e.target.value)}} type="text" className="form-control" id="name" placeholder="Your Name"/>
                                            <label for="name">الاسم </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input  onChange={(e)=>{setEmail(e.target.value)}} type="email" className="form-control" id="email" placeholder="Your Email"/>
                                            <label for="email">الايميل</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input  onChange={(e)=>{setSubject(e.target.value)}} type="text" className="form-control" id="subject" placeholder="Subject"/>
                                            <label for="subject">الموضوع</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea  onChange={(e)=>{setText(e.target.value)}} className="form-control" placeholder="Leave a message here" id="message"  style={{height:"150px"}}></textarea>
                                            <label for="message">الرسالة</label>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <button className="btn btn-primary-gradient rounded-pill py-3 px-5" type="submit" onClick={add}>ارسلها الان </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    

        <div className="container-fluid bg-primary text-light footer wow fadeIn" data-wow-delay="0.1s">
    <div className="container py-5 px-lg-5 text-center"> {/* Add text-center class here */}
        <div className="row g-5">
        <div className="col-md-6 col-lg-6" style={{margin:'auto'}}>
            <h1 className="text-white mb-4">Maktabi</h1>
                <h4 className="text-white mb-4">شريكك المثالي للتسيير العقاري</h4>
                
                
            </div>
        <div  className="col-md-6 col-lg-6">
                <h4 className="text-white mb-4">روابط سريعة  </h4>
                <div>
                    <a  className="btn btn-link" href="#home">الرئيسية </a>
                    <a className="btn btn-link" href="#about">حول المنصة </a>
                    <a className="btn btn-link" href="#feature">خدماتنا </a>
                    <a className="btn btn-link" href="#pricing">باقات الاشتراك </a>
                    <a className="btn btn-link" href="#contact">تواصل معنا</a>
                </div>
            </div>
           
           
        </div>
    </div>
</div>

        


      
        <a href="#" className="btn btn-lg btn-lg-square back-to-top pt-2"><i className="bi bi-arrow-up text-white"></i></a>
    </div>


        </div>
       )}</>
      
    
    );
}




