
import React,{useState,useRef} from "react";
import { NavLink } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,Stack,RadioGroup,
  Text,
  useColorModeValue,Radio
} from "@chakra-ui/react";
import { useHistory } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react'
import ReactLoading from 'react-loading';
import { useDisclosure } from '@chakra-ui/hooks';
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import axios from "axios";
import { useCookies } from 'react-cookie';
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { BiErrorCircle } from "react-icons/bi";
import { BASE_URL_1, BASE_URL_2 } from '../../../config';
import { FaSmile } from "react-icons/fa";

function SignIn() {
  const recaptcha = useRef();
  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
  )
  const [loading,setLoading]=useState(false) 
  const iconColor = useColorModeValue("brand.500", "white");
  const [cookies, setCookie, removeCookie] = useCookies(['name','id','create_at','id_office','pricing','passwor','type', 'email','token','isOffice','phone','phone_01','phone_2','phone_fixe','fall_number','establishment_number','address']);
  const [overlay, setOverlay] = useState(<OverlayOne />)
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure(); // First modal
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure(); // First modal
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure(); // First modal
  const { isOpen: isOpen4, onOpen: onOpen4, onClose: onClose4 } = useDisclosure(); // First modal
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
 
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [email,setEmail]=useState('')
  const [password,setPassword]=useState('')
  const [isOffice,setIsOffice]=useState('')
  const[message,setMessage]=useState('')
  const [code,setCode]=useState('')
  const [code2,setCode2]=useState('')
   const handleSubmit= async =>{
    setLoading(true)
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      setLoading(false)
      setMessage("تاكد من انك لست روبوت")
      setOverlay(<OverlayOne />)
      onOpen1()
    } else {
      axios.post(`${BASE_URL_2}/users/user/login`, { email, password })
      .then((response) => {
        const result = response.data; // No need to parse JSON, response.data is already parsed
        if(result.message)
        { setLoading(false)
          setMessage(result.message)
          setOverlay(<OverlayOne />)
          onOpen1()
        }
        else{
          if(result.office==true){
            const id=result.user.id_office
             const isOffice="true"
            axios.post(`${BASE_URL_2}/users/alert`,{id,isOffice})
            .then((response) => {
              const result = response.data
          
            })
          setCookie('pricing', result.user.price, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('password', password, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('token', result.token, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('name', result.user.name, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('id', result.user.id_office, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('id_office', result.user.id_office, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('email', result.user.email, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('phone_01', result.user.phone_01, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('phone_02', result.user.phone_02, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('phone_fixe', result.user.phone_fixe, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('isOffice', 'true', { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('create_at', result.user.create_at, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('fall_number', result.user.fall_number, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('establishment_number', result.user.establishment_number, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('address', result.user.address, { path: '/', expires: new Date(Date.now() + 86400000) });
          setLoading(false)
          history.push('/dashboard/real-estates');
        }
        else{
          const isOffice="false"
          const id=result.user.id_user
          axios.post(`${BASE_URL_2}/users/alert`,{id,isOffice})
          .then((response) => {
            const result = response.data
           
          })
          setCookie('password', password, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('fall_number', result.user.fall_number, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('pricing', result.user.price, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('create_at', result.user.create_at, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('id_office', result.user.id_office, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('token', result.token, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('type', result.user.type, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('id', result.user.id_user, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('name', result.user.name, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('email', result.user.email, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('phone', result.user.phone, { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('isOffice', 'false', { path: '/', expires: new Date(Date.now() + 86400000) });
          setCookie('address', result.user.address, { path: '/', expires: new Date(Date.now() + 86400000) });
          setLoading(false)
          history.push('/dashboard/real-estates');
        
        }}
     
      })
      .catch((error) => {
        console.log(error)}
      )
 
      .catch((error) => {
        console.log(error)
        
       
      });
    }}
const  changeReques= async()=>{
  axios.post(`${BASE_URL_2}/users/user/login/changeRequest`, { email, password })
      .then((response) => {
        const result = response.data;
       setCode(result.code)
       onClose2()
       onOpen3()
      })

}
const  changeCheck= async()=>{
  axios.post(`${BASE_URL_2}/users/user/login/changeCheck`, { email, password,code,code2 })
      .then((response) => {
        const result = response.data;
      if(result.message){
        onClose3()
        setMessage(result.message)
          setOverlay(<OverlayOne />)
          onOpen1()
      }
      else{
        onClose3()
      }
      })
    }
    return ( <> 
      {loading==true?   <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh', // Set minimum height to fill the viewport
    
      }}> <ReactLoading  type="spin" color="#422AFB" height={100} width={80} /></div>
     :( 
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto",md:'auto',xl:"0px" }}
        me='auto'
        h='100%'
        alignItems='left'
        textAlign='right'
        justifyContent='right'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box >
          <Heading color={textColor} fontSize='36px' mb='10px'>
           تسجيل الدخول
          </Heading>
          <Text
          
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
           ادخل البريد الالكتروني و كلمة المرور لتسجيل الدخول 
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
        
          <Flex align='center' mb='25px'>
            <HSeparator />
          
            <HSeparator />
          </Flex>
          <FormControl>
          <FormLabel
            display='flex'
            alignItems='center'  // Align label text vertically to the center
            justifyContent='flex-end'  // Align label text to the right
            fontSize='sm'
            fontWeight='500'
            color={textColor}
            mb='8px'
            pr='4px'  // Add right padding to create space between label text and input field
          >
            البريد الالكتروني<Text color={brandStars}>*</Text>
          </FormLabel>
            <Input
            
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='mail@simmmple.com'
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={(event)=> {
                setEmail(event.target.value)
             
              }}
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'
              justifyContent='flex-end'  >
              كلمة المرور<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='Min. 8 characters'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                onChange={(event)=> {
                  setPassword(event.target.value)
                }}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
           
            {/* <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='end'
            maxW='100%'
            mt='0px'>
             <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'
              justifyContent='flex-end'  >
               نوع الحساب<Text color={brandStars}></Text>
            </FormLabel>
            <RadioGroup defaultValue='2' >
                <Stack  ms={{ base: "0px", md: "0px" }} mr='10px' mt='10px' fontSize='sm'  mb='24px' spacing={5} direction='row' >
                  <Radio   onChange={(event)=> {
                setIsOffice(event.target.value)
              }}  fontSize='sm'  value='true'    >
                    صاحب مكتب
                  </Radio>
                  <Radio    onChange={(event)=> {
                setIsOffice(event.target.value)
              }}  fontSize='sm'  value='false'>
                    مستخدم عادي
                  </Radio>
                </Stack>
              </RadioGroup>
          </Flex>
             */}
            
            <Button
            disabled={email&&password ?false :true}
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px' type="submit" 
              onClick={handleSubmit} >
             تسجيل الدخول
            </Button>
           
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='600'
              color={textColor}
              display='flex'
              cursor={"pointer"}
              justifyContent='flex-start'  
              onClick={onOpen2} >
               نسييت كلمة المرور؟
            </FormLabel>
           
          </FormControl>
          <div style={{display:"flex",justifyContent:'center'}}><ReCAPTCHA style={{margin:"auto"}} ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} hl="ar" /></div>
            
        </Flex>
  
        <Modal isCentered    isOpen={isOpen1} onClose={onClose1}>
        {overlay}
        <ModalContent textAlign="center">
          <ModalHeader>حدث خطا</ModalHeader>
          <Icon as={BiErrorCircle} width='50px' height='50px' color='red' margin="auto" />
          <ModalCloseButton />
          <ModalBody>
            <Text>{message}</Text>
          </ModalBody>
          <ModalFooter>
            <Button  onClick={onClose1}>اغلاق</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isCentered    isOpen={isOpen2} onClose={onClose2}>
      {overlay}
        <ModalContent textAlign="center">
          <ModalHeader> تعديل كلمة  المرور </ModalHeader>
         
          <ModalCloseButton />
          <ModalBody>
          
       
            <FormControl dir='rtl' >
              <FormLabel>  كلمة المرور الجديدة</FormLabel>
              <Input   onChange={(event)=>{setPassword(event.target.value)}}/>
            </FormControl>
            
           
            
            
          </ModalBody>
          <ModalFooter  justifyContent="space-between">
            <Button  onClick={onClose2}>اغلاق</Button>
            <Button    backgroundColor={bgButton}   color={iconColor}   onClick={changeReques}>ارسال</Button>
          </ModalFooter>
          </ModalContent>
          </Modal>

          <Modal isCentered    isOpen={isOpen3} onClose={onClose3}  textAlign="center">
      {overlay}
        <ModalContent textAlign="center">
          <ModalHeader>  ادخل الكود الذي ارسل اليك عبر بريدك الالكتروني</ModalHeader>
         
          <ModalCloseButton />
          <ModalBody>
          
         
          
            <FormControl  dir='rtl' textAlign="center" alignItems="center" justifyContent="center">
              <FormLabel justifyContent="flex-end">الكود</FormLabel>
              <Input   onChange={(event)=>{setCode2(event.target.value)}}/>
            </FormControl> 
       
            
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button  onClick={onClose3}>اغلاق</Button>
            <Button  backgroundColor={bgButton}   color={iconColor}   onClick={changeCheck}>ارسال</Button>
          </ModalFooter>
          </ModalContent>
          </Modal>
      </Flex>
    </DefaultAuth>)}</>
  );
}

export default SignIn;

