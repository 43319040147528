import React,{useState,useEffect} from 'react'

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,SimpleGrid,Flex,Text, useColorModeValue,Icon,Button  ,Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  ModalCloseButton,FormControl,FormLabel,Input,
} from '@chakra-ui/react'
import { SocialIcon } from 'react-social-icons'
import { MdOutlineEdit } from 'react-icons/md';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { IoIosAddCircle } from 'react-icons/io';
import { useDisclosure } from '@chakra-ui/hooks';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import Card from "components/card/Card";
import { useParams } from "react-router-dom";
import { BASE_URL_1,BASE_URL_2 } from 'config';
export  function SocialMedia ()  {
    const { clientId } = useParams();

    const[twiter,setTwiter]=useState('')
    const[snapchat,setSnapchat]=useState('')
    const[facebook,setFacebook]=useState('')
    const[instgram,setInstgram]=useState('')
    const[tiktok,setTikok]=useState('')
    const[whatsapp,setWhatsapp]=useState('')
    useEffect(() => {
        const id_user=clientId
      
         axios.post(`${BASE_URL_2}/users/user/info`,{id_user})
       .then((response) => {
         const result = response.data
         
        
         const email=(result.user?result.user.email:"")
         axios.post(`${BASE_URL_2}/users/media/get`,{email})
         .then((response) => {
           const result = response.data
           
           setMedia(result.media)
           setFacebook(result.media.facebook)
            setInstgram(result.media.instgram)
           setWhatsapp(result.media.whatsapp)
           setTikok(result.media.tiktok)
           setTwiter(result.media.twiter)
           setSnapchat(result.media.snapchat) 
         })
       
       })

       
       }, [])
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const [media,setMedia]=useState([])
      const iconColor = useColorModeValue("brand.500", "white");
      const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <>
     <Box bg="#FAFAFA"  height={"100vh"}>
       <Box
      pt={{ base: "130px", md: "50px", xl: "80px" }}
      mx="auto"
      maxW="800px"
  
      textAlign="center"
  
    >
      <SimpleGrid
        mb="20px"
        border="3px"
        borderRadius="8px"
        borderColor={iconColor}
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {/* First Card */}
        <Flex
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "hidden", lg: "hidden" }}
        >
          <Flex
            px="25px"
            flexDir="column"
            justify="center"
            mb="50px"
            align="center"
          >
            <Text
              color={textColor}
              fontSize="33px"
              fontWeight="700"
              lineHeight="100%"
              marginTop="50px"
              mb="50px"
            >
              Maktabi
            </Text>
            <Text
              color={iconColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              شريكك المثالي للتسيير العقاري
            </Text>
          </Flex>
        </Flex>

       
        <Flex
          direction="column"
          maxW="400px"
          px="0px"
          justify="center"
          textAlign="center"
          alignItems="center"
          m={"auto"}
          overflowX={{ sm: "hidden", lg: "hidden" }}
        >
          <Flex px="25px" justify="center"  mb="50px" align="center">
            
          </Flex>

          <SimpleGrid columns={{ sm: 2, md: 2}} spacing={1}>
            {/* Social Media Links */}
            {/* Replace social media URLs with actual URLs */}
            {[
              { name: "Twitter", url:twiter?twiter: "https://twitter.com" , key: "twiter" },
              { name: "WhatsApp", url:whatsapp?whatsapp: "https://whatsapp.com", key: "whatsapp" },
              { name: "Facebook", url:facebook?facebook:"https://facebook.com", key: "facebook" },
              { name: "Instagram", url:instgram?instgram: "https://instagram.com", key: "instgram" },
              { name: "Snapchat", url:snapchat?snapchat: "https://snapchat.com", key: "snapchat" },
              { name: "TikTok", url: tiktok?tiktok: "https://tiktok.com", key: "tiktok" },
            ].map((socialMedia) => (
              <Flex key={socialMedia.key} px="25px" justify="center" mb="20px" align="center">
                <Text color={textColor} lineHeight="100%">
                  <SocialIcon url={socialMedia.url} />
                </Text>
                
              </Flex>
            ))}
          </SimpleGrid>
        </Flex>
      </SimpleGrid>
    </Box>
    </Box>
    </>
  )
}
